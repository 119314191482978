import React, { useEffect, useState } from "react";
import "./withdraw.css";
import {
  Button,
  InputPicker,
  Message,
  Pagination,
  Panel,
  useToaster,
} from "rsuite";
import { sendGet, sendPost, sendPut } from "../../../axios/axiosClient";
import { Table } from "react-bootstrap";

const Withdraw = () => {
  const [listWithdraws, setListWithdraws] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(3);
  const statusData = [
    {
      value: 0,
      label: "Đang chờ",
    },
    {
      value: 1,
      label: "Lỗi",
    },
    {
      value: 2,
      label: "Hoàn thành",
    },
  ];
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListWithdraw = async () => {
    try {
      const input = {
        page: page,
        page_size: limit,
      };
      const res = await sendGet(`/admin/withdraws`, input);
      if (res.status === 200) {
        setListWithdraws(res.data.data?.data);
        setTotalItems(res.data.data?.total);
      }
    } catch (error) {}
  };

  const handleUpdate = async () => {
    try {
      const input = {
        status: updateStatus,
      };
      const res = await sendGet(`/admin/withdraws/update-status/${id}`, input);
      if (res.status === 200) {
        setTextMessage("Cập nhật thành công.");
        getListWithdraw();
        setId(0);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case 0:
        return "Đang chờ";
      case 1:
        return "Lỗi";
      case 2:
        return "Hoàn thành";
      default:
        break;
    }
  };

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListWithdraw();
  }, [limit, page]);

  return (
    <div className="withdraw">
      <Panel shaded>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Mã giao dịch</th>
              <th>Mã ngân hàng</th>
              <th>Ngân hàng</th>
              <th>Số tiền</th>
              <th>Người tạo</th>
              <th>SĐT</th>
              <th>Họ tên</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listWithdraws?.map((item, idx) => {
              if (item?.id === id) {
                return (
                  <tr key={item?.id}>
                    <td>{++idx}</td>
                    <td>{item?.transaction_code}</td>
                    <td>{item?.bank_code}</td>
                    <td>{item?.bank}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.to_receive}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.fullname}</td>
                    <td>
                      <InputPicker
                        data={statusData}
                        defaultValue={item?.status}
                        onChange={setUpdateStatus}
                      />
                    </td>
                    <td>
                      <Button
                        className="ms-2"
                        appearance="primary"
                        color="green"
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                      <Button
                        className="ms-2"
                        appearance="primary"
                        color="red"
                        onClick={handleUpdate}
                      >
                        Cancel
                      </Button>
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr key={item?.id}>
                    <td>{++idx}</td>
                    <td>{item?.transaction_code}</td>
                    <td>{item?.bank_code}</td>
                    <td>{item?.bank}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.to_receive}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.fullname}</td>
                    <td>{handleStatus(item?.status)}</td>
                    <td>
                      <Button
                        className="ms-2"
                        appearance="primary"
                        color="cyan"
                        onClick={() => {
                          setId(item?.id);
                        }}
                      >
                        Update Status
                      </Button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Withdraw;

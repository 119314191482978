import React, { useRef } from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import { useEffect } from "react";
import "./post.css";
import { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputPicker,
  Message,
  Modal,
  Pagination,
  Panel,
  TagPicker,
  useToaster,
} from "rsuite";
import Table from "react-bootstrap/Table";
import {
  AiFillCloseCircle,
  AiOutlineCloseCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import ReactQuill from "react-quill";

const Post = () => {
  const [listPosts, setListPosts] = useState([]);
  const createImageRef = useRef(null);
  const updateImageRef = useRef(null);
  const [updateImage, setUpdateImage] = useState([]);

  // *pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [id, setId] = useState(0);
  const [formValue, setFormValue] = useState({
    title: "",
    content: "",
    description: "",
    status: 0,
    category_id: [],
    images: [],
  });
  const [formUpdate, setFormUpdate] = useState({
    title: "",
    status: 0,
    category_id: [],
  });
  const [listCategories, setListCategories] = useState([]);

  // * modal
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setFormValue({
      title: "",
      content: "",
      description: "",
      status: 0,
      category_id: [],
    });
  };
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // * message
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );
  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const statusData = [
    {
      value: 0,
      label: "Active",
    },
    {
      value: 1,
      label: "Inactive",
    },
  ];

  const getListPosts = async () => {
    try {
      const input = {
        page: page,
        page_size: limit,
      };
      const res = await sendGet(`/admin/posts`, input);
      if (res.status === 200) {
        setListPosts(res.data?.data?.data);
        setTotalItems(res.data.data.total);
      }
    } catch (error) {}
  };

  const getListCategory = async () => {
    try {
      const input = {
        page_size: 999,
      };
      const res = await sendGet(`/admin/categories`, input);
      if (res.status === 200) {
        setListCategories(
          res.data.data?.data?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      }
    } catch (error) {}
  };

  const handleCreate = async () => {
    try {
      const input = new FormData();
      input.append("title", formValue?.title);
      input.append("status", formValue?.status);
      input.append("description", formValue?.description);
      input.append("content", formValue?.content);
      formValue.category_id?.map((item) => {
        input.append("category_id[]", item);
      });
      formValue.images?.map((item) => {
        input.append("images[]", item);
      });
      const res = await sendPost(`/admin/posts`, input);
      if (res.status === 200) {
        setTextMessage("Tạo mới thành công");
        getListPosts();
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleUpdate = async () => {
    try {
      let updateImgIdx = 0;
      const input = new FormData();
      input.append("title", formUpdate?.title);
      input.append("status", formUpdate?.status);
      input.append("description", formUpdate?.description);
      input.append("content", formUpdate?.content);
      formUpdate.category_id?.map((item) => {
        input.append("category_id[]", item);
      });
      formUpdate.images?.map((item) => {
        input.append(`images[${updateImgIdx}]`, item);
        updateImgIdx++;
      });
      updateImage?.map((item) => {
        input.append(`images[${updateImgIdx}]`, item);
        updateImgIdx++;
      });
      const res = await sendPost(`admin/posts/${formUpdate?.id}`, input);
      if (res.status === 200) {
        setTextMessage("Cập nhật Post thành công.");
        getListPosts();
        handleCloseUpdate();
        setUpdateImage([]);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admin/posts/${id}`);
      if (res.status === 200) {
        setTextMessage("Xoá thành công.");
        getListPosts();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    getListCategory();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListPosts();
  }, [limit, page]);

  return (
    <div>
      <Modal open={openCreate} onClose={handleCloseCreate} backdrop="static">
        <Modal.Header>
          <Modal.Title>TẠO MỚI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid formValue={formValue} onChange={setFormValue}>
            <Form.Group>
              <Form.ControlLabel>Tiêu đề</Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Nội dung</Form.ControlLabel>
              <ReactQuill
                value={formValue?.content}
                onChange={(e) => setFormValue({ ...formValue, content: e })}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Mô tả</Form.ControlLabel>
              <ReactQuill
                value={formValue?.description}
                onChange={(e) => setFormValue({ ...formValue, description: e })}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Hình ảnh</Form.ControlLabel>
              <Button
                appearance="primary"
                onClick={() => createImageRef.current.click()}
              >
                Thêm mới ảnh
              </Button>
              <input
                type="file"
                ref={createImageRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e) {
                    setFormValue({
                      ...formValue,
                      images: [...formValue?.images, e.target.files[0]],
                    });
                  }
                }}
              />
              <div className="image-box">
                {formValue?.images?.map((item, idx) => (
                  <div className="image-item" key={idx}>
                    <AiFillCloseCircle
                      className="remove-image-button"
                      onClick={() => {
                        setFormValue({
                          ...formValue,
                          images: [
                            ...formValue?.images.filter((x) => x !== item),
                          ],
                        });
                      }}
                    />
                    <img src={URL.createObjectURL(item)} alt="create-image" />
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control
                className="w-100"
                name="status"
                accepter={InputPicker}
                data={statusData}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Loại</Form.ControlLabel>
              <Form.Control
                className="w-100"
                name="category_id"
                accepter={TagPicker}
                data={listCategories}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openUpdate} onClose={handleCloseUpdate} backdrop="static">
        <Modal.Header>
          <Modal.Title>Cập nhật</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid formValue={formUpdate} onChange={setFormUpdate}>
            <Form.Group>
              <Form.ControlLabel>Tiêu đề</Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Nội dung</Form.ControlLabel>
              <ReactQuill
                value={formUpdate?.content}
                onChange={(e) => setFormUpdate({ ...formUpdate, content: e })}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Mô tả</Form.ControlLabel>
              <ReactQuill
                value={formUpdate?.description}
                onChange={(e) =>
                  setFormUpdate({ ...formUpdate, description: e })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Hình ảnh</Form.ControlLabel>
              <Button
                appearance="primary"
                onClick={() => updateImageRef.current.click()}
              >
                Thêm mới ảnh
              </Button>
              <input
                type="file"
                ref={updateImageRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e) {
                    setUpdateImage([...updateImage, e.target.files[0]]);
                  }
                }}
              />
              <div className="image-box">
                {formUpdate?.images?.map((item, idx) => (
                  <div className="image-item" key={idx}>
                    <AiFillCloseCircle
                      className="remove-image-button"
                      onClick={() => {
                        setFormUpdate({
                          ...formUpdate,
                          images: [
                            ...formUpdate?.images.filter((x) => x !== item),
                          ],
                        });
                      }}
                    />
                    <img src={item} alt="create-image" />
                  </div>
                ))}
                {updateImage?.map((item, idx) => (
                  <div className="image-item" key={idx}>
                    <AiFillCloseCircle
                      className="remove-image-button"
                      onClick={() => {
                        setUpdateImage([
                          ...updateImage.filter((x) => x !== item),
                        ]);
                      }}
                    />
                    <img src={URL.createObjectURL(item)} alt="create-image" />
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control
                className="w-100"
                name="status"
                accepter={InputPicker}
                data={statusData}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Loại</Form.ControlLabel>
              <Form.Control
                className="w-100"
                name="category_id"
                accepter={TagPicker}
                data={listCategories}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>XOÁ</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá bài viết này?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end gap-2">
          <div>
            <InputGroup>
              <Input placeholder={"Search"} />
              <InputGroup.Addon>
                <AiOutlineSearch />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <Button appearance="primary" onClick={handleOpenCreate}>
            Thêm mới
          </Button>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Tiêu đề</th>
              <th>Nội dung</th>
              <th>Mô tả</th>
              <th>Hình ảnh</th>
              <th>Loại</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listPosts?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx}</td>
                <td>{item?.title}</td>
                <td>{item?.content}</td>

                <td>{item?.description}</td>
                <td style={{ width: "300px" }}>
                  <div className="image-box-table">
                    {item?.images?.map((image, idx) => (
                      <div className="image-item-table" key={idx}>
                        <a
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={image} alt="image" />
                        </a>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  {item?.categories?.map((x, index) => {
                    if (index) {
                      return ", " + x?.name;
                    } else {
                      return x?.name;
                    }
                  })}
                </td>
                <td>{item?.status ? "Tạm dừng" : "Hoạt động"}</td>
                <td>
                  <Button
                    className="ms-2"
                    onClick={() => {
                      setFormUpdate({
                        id: item?.id,
                        title: item?.title,
                        content: item?.content,
                        description: item?.description,
                        images: item?.images,
                        status: item?.status,
                        category_id: item?.categories?.map((x) => x.id),
                      });
                      handleOpenUpdate();
                    }}
                  >
                    Sửa
                  </Button>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Xoá
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Post;

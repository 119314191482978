import React from "react";
import "./footer.css";
import { Button } from "rsuite";
import { IoIosCall, IoIosShareAlt } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import facebook_logo from "../../assets/images/connect-with-us/facebook-logo_30.webp";
import youtube_logo from "../../assets/images/connect-with-us/youtube-logo_50.png";
import instagram_logo from "../../assets/images/connect-with-us/instagram-logo_20.webp";
import tiktok_logo from "../../assets/images/connect-with-us/tiktok-logo_25.webp";
import footer_logo from "../../assets/images/logo/logo-tuvihangngay-gold.png";

const Footer = () => {
  return (
    <div className="home-footer">
      <div className="home-footer-item ">
        <div className="d-flex gap-2 flex-lg-non-wrap align-items-center text-white mb-2">
          <div>
            <Link to={"/"}>
              <img
                loading="lazy"
                src={footer_logo}
                alt=""
                className="footer-logo"
              />
            </Link>
          </div>
          <Button appearance="primary" className="share-button">
            <IoIosShareAlt className="me-2" />
            Share
          </Button>
        </div>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fphongthuythienan58&tabs=timeline&width=340&height=250&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
          height="250"
          className="facebook-iframe"
          scrolling={"no"}
          frameBorder={0}
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
        {/* <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fphongthuythienan58&tabs=timeline&width=340&height=250&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
          width="340"
          height="250"
          // style="border:none;overflow:hidden"
          style={{ border: "none", overflow: "hidden" }}
          scrolling={"no"}
          frameBorder={0}
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe> */}
      </div>
      <div className="home-footer-item">
        <p className="home-footer-item-heading">Liên hệ</p>
        <p>
          <IoIosCall className="me-2" />
          Hotline: 0981.868.775
        </p>
        <p>
          <MdEmail className="me-2" />
          Email: luangiaituvi.thienan@gmail.com
        </p>
        <p>
          <FaLocationDot className="me-2" />
          Địa chỉ: Phạm Hùng, Nam Từ Liêm, Hà Nội
        </p>
      </div>
      <div className="home-footer-item">
        <p className="home-footer-item-heading">Kết nối với chúng tôi</p>
        <div className="d-flex gap-3 connect-with-us">
          <img loading="lazy" src={tiktok_logo} alt="social-logo" />
          <img loading="lazy" src={facebook_logo} alt="social-logo" />
          <img loading="lazy" src={youtube_logo} alt="social-logo" />
          <img loading="lazy" src={instagram_logo} alt="social-logo" />
          {/* <FaTiktok className="icon-tiktok" />
          <FaFacebook className="icon-facebook" />
          <FaYoutube className="icon-youtube" />
          <FaInstagram className="icon-instagram" /> */}
        </div>
        <p>
          <Link>Hợp tác affiliate</Link>
        </p>
        <p>
          <Link>Mua gói VIP</Link>
        </p>
        <p>
          <Link>Câu hỏi thường gặp</Link>
        </p>
      </div>
      <div className="home-footer-item">
        <p className="home-footer-item-heading">Thông tin chung</p>
        <p>
          <Link to={""}>Chính sách bảo mật</Link>
        </p>
        <p>
          <Link to={""}>Điều khoản sử dụng</Link>
        </p>{" "}
      </div>
    </div>
  );
};

export default Footer;

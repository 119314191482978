import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Loader,
  Message,
  useToaster,
  Form,
  Checkbox,
} from "rsuite";
import "./register.css";
import { sendGet, sendPost } from "../../../axios/axiosClient";

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [checkRule, setCheckRule] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    rePassword: "",
  });

  const type = "warning";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleRegister = async () => {
    if (!checkRule) {
      setTextMessage("Bạn chưa đồng ý với điều khoản.");
      return;
    }
    if (formValue.password.trim() !== formValue.rePassword.trim()) {
      setTextMessage("Mật khẩu và xác nhận không trùng nhau.");
      return;
    }
    setIsLoading(true);
    try {
      const res = await sendPost(`/register`, formValue);
      if (res.status === 200) {
        setTextMessage("Đăng ký thành công.");
        navigate(`/login${location?.search}`);
        // setTimeout(() => navigate(`/login${location?.search}`), 1000);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
    setIsLoading(false);
  };

  return (
    <div className="login">
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "10vh" }}
      >
        <div className="loginBody p-3">
          <Link to={"/"}>
            <div className="page-logo"></div>
          </Link>
          <div className="text-center my-2">
            <h4>Đăng ký tài khoản mới</h4>
            <p>Tạo tài khoản để xem đầy đủ luận giải Tử vi</p>
          </div>
          <Form fluid formValue={formValue} onChange={setFormValue}>
            <Form.Group controlId="name">
              <Form.Control
                name="name"
                type="text"
                placeholder="Họ và tên"
                className="rounder-input"
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                className="rounder-input"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                name="password"
                type="password"
                autoComplete="off"
                placeholder="Mật khẩu mới"
                className="rounder-input"
              />
            </Form.Group>
            <Form.Group controlId="rePassword">
              <Form.Control
                name="rePassword"
                placeholder="Nhập lại mật khẩu mới"
                type="password"
                autoComplete="off"
                className="rounder-input"
              />
            </Form.Group>
            <Checkbox onChange={(e, check) => setCheckRule(check)}>
              Tôi ĐỒNG Ý với điều khoản sử dụng
            </Checkbox>
            <Form.Group>
              <ButtonToolbar>
                <Button
                  appearance="default"
                  className="login-button"
                  style={{ width: "100%" }}
                  onClick={handleRegister}
                >
                  {isLoading ? <Loader /> : "ĐĂNG KÝ"}
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
          <div className="mt-3 d-flex justify-content-center">
            <span>
              Đã có tài khoản?
              <Link to={`/login${location?.search}`} className="register-link">
                ĐĂNG NHẬP NGAY
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

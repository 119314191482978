import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
const axiosClient = axios.create({
  //   baseURL: 'process.env.REACT_APP_API_URL',
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-type': 'application/json',
    Authorization: "Bearer " + Cookies.get("token"),
    "ngrok-skip-browser-warning": true,
  },
  paramsSerializer: {
    serialize: (params) => {
      return new URLSearchParams(params).toString();
    },
  },
});

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  useEffect(() => {
    const interceptor = axiosClient.interceptors.request.use(async (config) => {
      const token = await Cookies.get("token");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    const interceptor2 = axiosClient.interceptors.response.use(
      (response) => {
        if (response && response.data) {
          return response;
        }
        return response;
      },
      (error) => {
        if (error.response?.config.url === "/user/info") {
          console.log("Token has expired");
          Cookies.remove("token");
        } else if (error.response.status === 401) {
          console.log("You don't have permission to go to this path");
          Cookies.remove("token");
          window.location.assign(window.location.origin + "/login");
        } else if (error.code == "ERR_NETWORK") {
          Cookies.remove("token");
          window.location.assign(window.location.origin + "/login");
        } else if (
          error?.response?.data?.code === 500 &&
          error?.response?.data?.message === "Token has expired"
        ) {
          window.location.assign(window.location.origin + "/login");
          Cookies.remove("token");
        }

        throw error;
      }
    );
    setIsSet(true);
    return () =>
      axiosClient.interceptors.response.eject(interceptor, interceptor2);
  }, []);

  return isSet && children;
};
export const sendGet = (url, params) => axiosClient.get(url, { params });
export const sendPost = (url, params, queryParams) =>
  axiosClient.post(url, params, { params: queryParams });
export const sendPut = (url, params) => axiosClient.put(url, params);
export const sendPatch = (url, params) => axiosClient.patch(url, params);
export const sendDelete = (url, params) => axiosClient.delete(url, params);
export default axiosClient;
export { AxiosInterceptor };

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import route from "../../route";
import User from "../../User";
import { Aside as AdminAside } from "../../admin/Aside";

const Aside = () => {
  const location = useLocation();

  const map = route.map((item, idx) => {
    switch (item?.type) {
      case "user":
        return {
          path: item?.path,
          element: <User />,
        };
      case "admin":
        return {
          path: item?.path,
          element: <AdminAside />,
        };
      default:
        return {
          path: item?.path,
          element: null,
        };
    }
  });

  return (
    <Routes>
      {map.map((item, key) => (
        <Route key={key} path={item?.path} element={item?.element} />
      ))}
    </Routes>
  );
};

export default Aside;

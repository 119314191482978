import React, { createContext, useEffect, useState } from "react";
import { sendGet } from "../axios/axiosClient";
import Cookies from "js-cookie";
export const InfoContextProvider = createContext({});

const InfoContext = ({ children }) => {
  const [info, setInfo] = useState(null);
  const loginToken = Cookies.get("token");

  const getUserInfo = async () => {
    try {
      const res = await sendGet(`/user/info`);
      if (res.status === 200) {
        setInfo(res.data?.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("guest_code")) {
      const start = Date.now();
      localStorage.setItem("guest_code", start + createRandomString(10));
    }
    if (Cookies.get("token")) {
      getUserInfo();
    }
  }, [loginToken]);

  const createRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  return (
    <InfoContextProvider.Provider value={{ info, setInfo }}>
      {children}
    </InfoContextProvider.Provider>
  );
};

export default InfoContext;

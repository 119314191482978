export const type = [
  {
    label: "Tổng quan",
    value: 0,
  },
  {
    label: "Mệnh",
    value: 1,
  },
  {
    label: "Phụ mẫu",
    value: 2,
  },
  {
    label: "Phúc đức",
    value: 3,
  },
  {
    label: "Điền trạch",
    value: 4,
  },
  {
    label: "Quan lộc",
    value: 5,
  },
  {
    label: "Nô bộc",
    value: 6,
  },
  {
    label: "Thiên di",
    value: 7,
  },
  {
    label: "Tật ách",
    value: 8,
  },
  {
    label: "Tài bạch",
    value: 9,
  },
  {
    label: "Tử nữ",
    value: 10,
  },
  {
    label: "Phu thê",
    value: 11,
  },
  {
    label: "Huynh đệ",
    value: 12,
  },
];

import React, { useEffect, useState } from "react";
import { sendGet, sendPost } from "../../../../axios/axiosClient";
import {
  Button,
  Form,
  Input,
  InputPicker,
  Message,
  MultiCascader,
  Pagination,
  Panel,
  Table,
  TagPicker,
  useToaster,
} from "rsuite";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { type as HoroscopeType } from "../../../../data/HoroscopeType";
import { statusPickerData } from "../../../../data/status";
import ReactQuill from "react-quill";
// import status from "../../../../data/status";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} rows={5} />
));

const CreateHoroscope = () => {
  const [listHoroscope, setListHoroscope] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [cons, setCons] = useState([]);
  const [cons_3_fits, setCons_3_fits] = useState([]);
  const [cons_vcd, setCons_vcd] = useState([]);
  const [listA, setListA] = useState([]); //LIST TYPE
  const [formValue, setFormValue] = useState({
    content: "",
  });

  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListHoroscope = async () => {
    try {
      const input = {
        page_size: 999,
      };
      const res = await sendGet(`admin/horoscopes`, input);
      if (res.status === 200) {
        setListHoroscope(res.data.data.data);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const getListHoroscopeType = async () => {
    try {
      const res = await sendGet(`/admin/horoscope-types?page_size=999`);
      if (res.status === 200) {
        setListA(
          res.data.data.data?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    getListHoroscope();
    getListHoroscopeType();
  }, []);

  const handleCreate = async () => {
    try {
      const res = await sendPost(`/admin/horoscope-posts`, formValue);
      if (res.status === 200) {
        setTextMessage("Thêm thành công.");
        navigate("/horoscope-post");
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    if (listA.length > 0 && listHoroscope.length > 0) {
      // conditions data
      setCons(
        listA?.map((item) => {
          return {
            ...item,
            children: listHoroscope
              ?.filter((horos) => horos.type_id === item.value)
              .map((obj) => ({ label: obj.name, value: obj.name })),
          };
        })
      );
      // conditions_3_fits data
      setCons_3_fits(
        listA
          ?.filter(
            (item) =>
              item?.value !== 1 && item?.value !== 3 && item?.value !== 12
          )
          ?.map((item) => {
            return {
              ...item,
              children: listHoroscope
                ?.filter((horos) => horos.type_id === item.value)
                .map((obj) => ({ label: obj.name, value: obj.name })),
            };
          })
      );
      // conditions_vcd data
      setCons_vcd(
        listHoroscope
          .filter((obj) => obj.type_id === 4)
          .map((x) => ({ label: x.name, value: x.name }))
      );
    }
  }, [listA, listHoroscope]);

  return (
    <div>
      <Panel shaded>
        <div className="mb-2">
          <IoIosArrowRoundBack
            className="fs-2"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/horoscope-post")}
          />
        </div>
        <Form fluid formValue={formValue} onChange={setFormValue}>
          <div className="d-flex">
            <Form.Group className="col-8 pe-2">
              <Form.ControlLabel>Tên</Form.ControlLabel>
              <Form.Control name="title" />
            </Form.Group>
            <Form.Group className="col-2 pe-2">
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control
                name="status"
                data={statusPickerData}
                accepter={InputPicker}
                style={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group className="col-2">
              <Form.ControlLabel>Kiểu</Form.ControlLabel>
              <Form.Control
                name="type"
                data={HoroscopeType}
                accepter={InputPicker}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
          <Form.Group>
            <Form.ControlLabel>Nội dung</Form.ControlLabel>
            <ReactQuill
              value={formValue?.content}
              onChange={(e) => setFormValue({ ...formValue, content: e })}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Cung xem</Form.ControlLabel>
            <Form.Control
              style={{ width: "100%" }}
              name="conditions"
              accepter={MultiCascader}
              data={cons}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Tam hợp</Form.ControlLabel>
            <Form.Control
              style={{ width: "100%" }}
              name="conditions_three_fit"
              accepter={MultiCascader}
              data={cons_3_fits}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Vô chính diệu</Form.ControlLabel>
            <Form.Control
              style={{ width: "100%" }}
              name="conditions_vcd"
              accepter={TagPicker}
              data={cons_vcd}
            />
          </Form.Group>
        </Form>
        <div className="mt-2">
          <Button appearance="primary" onClick={handleCreate}>
            Gửi
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default CreateHoroscope;

import React, { useEffect, useState } from "react";
import { sendDelete, sendGet } from "../../../axios/axiosClient";
import {
  Button,
  Input,
  InputGroup,
  Message,
  Modal,
  Pagination,
  Panel,
  useToaster,
} from "rsuite";
import Table from "react-bootstrap/Table";
import { AiOutlineSearch } from "react-icons/ai";

const Affiliates = () => {
  const [listAffiliates, setListAffiliates] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListAffiliates = async () => {
    try {
      const res = await sendGet(`/admin/affiliates`);
      if (res.status === 200) {
        setListAffiliates(res.data.data?.data);
        setTotalItems(res.data.data.total);
      }
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admin/affiliates/${id}`);
      if (res.status === 200) {
        setTextMessage("Xoá Affiliate thành công.");
        handleCloseDelete();
        getListAffiliates();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    getListAffiliates();
  }, []);

  return (
    <div>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>XOÁ</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn xoá bỏ chi nhánh này?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end">
          <div>
            <InputGroup>
              <Input placeholder={"Search"} />
              <InputGroup.Addon>
                <AiOutlineSearch />
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Tên</th>
              <th>Đường dẫn</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listAffiliates?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx}</td>
                <td>{item?.name}</td>
                <td>{item?.link}</td>
                <td>{item?.status ? "Tạm ngừng" : "Hoạt động"}</td>
                <td>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Affiliates;

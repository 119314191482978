import Withdraw from "../components/admin/withdraw/Withdraw";
import Login from "../components/auth/login-user/Login";
import Home from "../components/admin/home/Home";
import { Home as UserHome } from "../components/user/home/Home";
import Content from "./admin/Content";
import Affiliates from "../components/admin/affiliates/Affiliates";
import HoroscopePost from "../components/admin/horoscope-post/HoroscopePost";
import Category from "../components/admin/category/Category";
import Post from "../components/admin/post/Post";
import Order from "../components/admin/order/Order";
import Coupon from "../components/admin/coupon/Coupon";
import Horoscrope from "../components/admin/horoscope/Horoscrope";
import CreateHoroscope from "../components/admin/horoscope-post/create/CreateHoroscope";
import Ketqua from "../components/user/ket-qua/Ketqua";
import UpdateHoroscope from "../components/admin/horoscope-post/edit/UpdateHoroscope";
import Laso from "../components/user/la-so/Laso";
import Register from "../components/auth/register-user/Register";
import User from "../components/user/user/User";
import Payment from "../components/user/payment/Payment";
import Posts from "../components/user/posts/Posts";
import Affiliate from "../components/user/affiliate/Affiliate";
import UserAffiliate from "../components/user/user-affiliate/UserAffiliate";
import ForgotPassword from "../components/auth/fotgot-password/ForgotPassword";

const route = [
  {
    path: "/ket-qua",
    type: "user",
    name: "Kết quả",
    element: <Ketqua />,
  },
  {
    path: "/affiliate",
    type: "user",
    name: "Affiliate",
    element: <Affiliate />,
  },
  {
    path: "/user-affiliate",
    type: "user",
    name: "Affiliate",
    element: <UserAffiliate />,
  },
  {
    path: "/",
    type: "user",
    name: "Trang chủ",
    element: <UserHome />,
  },
  {
    path: "/user",
    type: "user",
    name: "Người dùng",
    element: <User />,
  },
  {
    path: "/confirm-payment",
    type: "user",
    name: "Thanh toán",
    element: <Payment />,
  },
  {
    path: "/posts",
    type: "user",
    name: "Bài viết",
    element: <Posts />,
  },
  {
    path: "/laso",
    type: "user",
    name: "Lá số",
    element: <Laso />,
  },
  {
    path: "/detail",
    type: "user",
    name: "Lá số",
    element: <Ketqua />,
  },
  {
    path: "/admin-home",
    type: "admin",
    name: "Trang chủ",
    element: <Home />,
  },
  {
    path: "/create-horoscope-post",
    type: "admin",
    name: "Tạo mới Luận giải",
    element: <CreateHoroscope />,
  },
  {
    path: "/update-horoscope-post",
    type: "admin",
    name: "Cập nhật Luận giải",
    element: <UpdateHoroscope />,
  },
  {
    path: "/withdraw",
    type: "admin",
    name: "Thanh toán",
    element: <Withdraw />,
  },
  {
    path: "/horoscope",
    type: "admin",
    name: "Cung và sao",
    element: <Horoscrope />,
  },
  {
    path: "/admin-affiliates",
    type: "admin",
    name: "Affiliates",
    element: <Affiliates />,
  },
  {
    path: "/horoscope-post",
    type: "admin",
    name: "Luận giải",
    element: <HoroscopePost />,
  },
  {
    path: "/category",
    type: "admin",
    name: "Loại",
    element: <Category />,
  },
  {
    path: "/admin-orders",
    type: "admin",
    name: "Đơn hàng",
    element: <Order />,
  },
  {
    path: "/admin-coupon",
    type: "admin",
    name: "Coupon",
    element: <Coupon />,
  },
  {
    path: "/admin-posts",
    type: "admin",
    name: "Bài viết",
    element: <Post />,
  },
  {
    path: "/login",
    type: "landing",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    type: "landing",
    element: <ForgotPassword />,
  },
  {
    path: "/register",
    type: "landing",
    element: <Register />,
  },
  // {
  //   path: "*",
  //   type: "admin",
  //   element: <Content />,
  // },
];

export default route;

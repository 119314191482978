import { Loader } from "rsuite";
import './autobank.css';
import ACB from "../../../assets/images/Logo-ACB.webp";

const Autobank = ({ amount, code }) => {
    const acbQR = `https://apiqr.web2m.com/api/generate/ACB/12515801/PHUNGPHUONGNAM?amount=${amount}&memo=TUVITHIENAN ${code}&is_mask=0&bg=0`;

    return (
        <div className="acb-container">
            <div className="title">
                <h5>Chuyển khoản qua Ngân Hàng</h5>
                <p>Thực hiện chuyển khoản ngân hàng vào số tài khoản bên dưới. Vui lòng nhập đúng nội dung chuyển khoản và chờ ở trang này cho đến khi hệ thống báo thành công</p>
                <div className="order-info">
                    <p><strong>Mã đơn hàng : {code}</strong></p>
                    <p><strong>Số tiền : {amount.toLocaleString()}</strong></p>
                </div>
            </div>
            <div className="acb-content">
                <div className="content-item">
                    <div className="content-left">
                        <div>
                            <h5>Cách 1 : Chuyển khoản bằng mã QR</h5>
                            <p>Mở App ngân hàng quet mã QRcode và nhập số tiền cần chuyển</p>
                        </div>
                        <img src={acbQR} alt="" />
                    </div>
                </div>
                <div className="content-item">
                    <div className="content-right">
                        <h5>Cách 2 : Chuyển khoản thủ công theo thông tin</h5>
                        <img src={ACB} alt="" />
                        <div className="info">
                            <div><p id="info-left">Chủ tài khoản</p><p id="info-right"><strong>PHÙNG PHƯƠNG NAM</strong></p></div>
                            <div><p id="info-left">Số tài khoản</p><p id="info-right" className="bank-code"><strong>12515801</strong></p></div>
                            <div><p id="info-left">Nội dung chuyển tiền</p><p id="info-right" className="syntax"><strong>TUVITHIENAN {code}</strong></p></div>
                            <div><p id="info-left">Ngân hàng</p><p id="info-right"><strong>Á CHÂU (ACB)</strong></p></div>
                        </div>
                        <p><strong>Đang chờ chuyển khoản</strong></p>
                        <div className="loader">
                            <Loader size="md" speed="slow" />
                        </div>
                        <p className="notice">Lưu ý : Nếu sau 2 phút chưa nhận được thông báo vui lòng F5 (reload) lại trang này</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Autobank;
import React from "react";
import "./affiliate.css";
import { Button } from "rsuite";
import register_image from "../../../assets/images/affiliate-user/section-2-img-3.png";
import share_image from "../../../assets/images/affiliate-user/section-2-img-1.png";
import affiliate_image from "../../../assets/images/affiliate-user/section-2-img-4.png";
import { Link } from "react-router-dom";

const Affiliate = () => {
  return (
    <div className="affiliate">
      <div className="section-1">
        <div className="section-1-main-text">
          <p className="fs-1 fw-bold">
            Chương trình <span style={{ color: "orange" }}>đối tác</span> kiếm
            tiền cùng Tra cứu thần số học
          </p>
          <p>
            Nhận ngay hoa hồng lên tới 30% doanh thu trên từng đơn hàng khi giới
            thiệu thành công khách hàng đến với Tra cứu thần số học.{" "}
          </p>
          <Button
            className="mt-3 px-5 py-2 fw-bold"
            style={{ borderRadius: "2rem" }}
            appearance="primary"
            color="orange"
          >
            Xem điều khoản
          </Button>
        </div>
        <div className="section-1-picture">
          <div className="section-1-main-picture"></div>
          <div className="d-flex gap-2 section-1-sub-picture">
            <div className="section-1-sub-1-picture"></div>
            <div className="section-1-sub-2-picture"></div>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="section-2-stats">
          <div className="section-2-stats-left">
            <div>
              <p className="fs-1 fw-bold">20%</p>
              <p>Hoa hồng lên đến 20%</p>
            </div>
            <div>
              <p className="fs-1 fw-bold">45%</p>
              <p>Tỉ lệ chuyển đổi thành công</p>
            </div>
            <div>
              <p className="fs-1 fw-bold">1</p>
              <p>Luôn luôn là vị trí số 1 trong ngành</p>
            </div>
          </div>
          <div className="section-2-stats-right">
            <p className="fs-1" style={{ color: "orange" }}>
              800.000+
            </p>
            <p className="mt-0">bản pdf đã xuất</p>
          </div>
        </div>
        <div className="section-2-content">
          <div className="section-2-content-heading">
            <p className="fs-6">
              Bạn chỉ cần giới thiệu khách hàng và nhận hoa hồng
            </p>
            <p className="fs-3 fw-bold">
              Phần còn lại{" "}
              <span style={{ color: "orange" }}>Tra cứu thần số học</span> sẽ
              lo!
            </p>
          </div>
          <div className="section-2-content-1">
            <div className="section-2-content-1-card">
              <div className="section-2-content-1-img-1"></div>
              <div>
                (1) Sau khi khách hàng đăng ký qua đường link affiliate riêng
                của bạn và nâng cấp lên gói trả phí, ngay lập tức bạn sẽ nhận
                được hoa hồng từ giá trị của giao dịch thành công.
              </div>
            </div>
            <div className="section-2-content-1-card">
              <div className="section-2-content-1-img-2"></div>
              <div>
                (2) Bạn chỉ cần giới thiệu khách hàng vào trang web qua đường
                link của bạn, tất cả vấn đề về chăm sóc khách hàng,
                Tracuuthansohoc.com sẽ lo hết cho bạn.{" "}
              </div>
            </div>
          </div>
          <div className="section-2-content-2">
            <p className="fs-2 fw-bold">
              3 BƯỚC KIẾM TIỀN CÙNG TRA CỨU THẦN SỐ HỌC
            </p>
            <div className="d-flex justify-content-around mt-5">
              <div className="section-content-2-card">
                <img
                  src={register_image}
                  alt="image"
                  className="section-2-content-2-image"
                />
                <p className="fw-bold my-3">ĐĂNG KÝ</p>
                <p>Đăng ký và sử dụng vip tại Tra cứu thần số học. </p>
              </div>
              <div className="section-content-2-card">
                <img
                  src={share_image}
                  alt="image"
                  className="section-2-content-2-image"
                />
                <p className="fw-bold my-3">CHIA SẺ</p>
                <p>
                  Tạo link affiliate và chia sẻ trên Facebook, Blog, Forum,
                  Quảng cáo...
                </p>
              </div>
              <div className="section-content-2-card">
                <img
                  src={affiliate_image}
                  alt="image"
                  className="section-2-content-2-image"
                />
                <p className="fw-bold my-3">NHẬN HOA HỒNG</p>
                <p>
                  Trình điều khiển sẽ thống kê real-time cho bạn về user, đơn
                  hàng...
                </p>
              </div>
            </div>
          </div>
          <div className="section-2-content-3">
            <p className="fw-bold fs-2">HOA HỒNG AFFILIATE ĐƠN HÀNG ĐẦU TIÊN</p>
            <p>Sẽ có 3 mức hoa hồng khác nhau tại thời điểm bạn rút tiền:</p>
            <div className="section-2-content-3-body">
              <div className="section-2-content-3-item">
                <p className="section-2-content-3-item-heading">10 người mua</p>
                <ul>
                  <li>Đây là mốc tối thiểu bạn có thể rút tiền.</li>
                  <li>Mức hoa hồng/doanh thu bạn nhận được sẽ là 10%</li>
                  <li>
                    Chúng tôi khuyên bạn không nên rút tiền ở mốc này để bạn có
                    thể nhận hoa hồng cao hơn ở mốc tiếp theo.
                  </li>
                </ul>
              </div>
              <div className="section-2-content-3-item">
                <p className="section-2-content-3-item-heading">30 người mua</p>
                <ul>
                  <li>Đây là mốc tiếp theo để bạn tăng hoa hồng.</li>
                  <li>Mức hoa hồng/doanh thu bạn nhận được sẽ là 20%</li>
                  <li>Chúng tôi khuyên bạn không nên rút tiền ở mốc này!</li>
                  <li>
                    Nếu bạn đã rút tiền ở mốc này, hệ thống sẽ tính lại hoa hồng
                    ở mốc 10 người mua hàng.
                  </li>
                </ul>
              </div>
              <div className="section-2-content-3-item">
                <p className="section-2-content-3-item-heading">50 người mua</p>
                <ul>
                  <li>Đây là mốc bạn có mức hoa hồng cao nhất.</li>
                  <li>Mức hoa hồng/doanh thu bạn nhận được sẽ là 30%</li>
                  <li>Chúng tôi khuyên bạn nên rút tiền ở mốc này!</li>
                  <li>
                    Nếu bạn đã rút tiền ở mốc này, hệ thống sẽ tính lại hoa hồng
                    ở mốc 10 người mua hàng.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section-2-content-4">
            <p className="fw-bold fs-2">TRỌN ĐỜI 10% KỂ TỪ ĐƠN HÀNG THỨ 2</p>
            <ul>
              <li>Đơn hàng đầu tiên bạn sẽ nhận được tối đa 30% doanh thu. </li>
              <li>
                Sau đó bạn sẽ nhận được 10% hoa hồng trọn đời của khách hàng,
                nếu khách thánh toán đơn thứ 2. Không giới hạn thời gian
              </li>
              <li>
                Đơn hàng thứ 2 được hiểu là 1 khách hàng mua nhiều hơn 2 đơn
              </li>
            </ul>
          </div>
        </div>
        <div className="section-3">
          <p
            className="fs-2 fw-bold text-center"
            style={{ color: "orange", marginBottom: "2rem" }}
          >
            HƯỚNG DẪN LẤY LINK AFFILIATE
          </p>
          <div>
            <div>
              <span className="fw-bold">Cách 1: </span>
              <span>
                Bạn có thể click vào đường dẫn sau để lấy link:{" "}
                <Link to={"/user-affiliate"} className="fw-bold text-dark">
                  {window.location.origin + "/user-affiliate"}
                </Link>{" "}
                (Lưu ý: Link chỉ hiển thị đối với tài khoản đã mua ít nhất 1 lần
                tra cứu)
              </span>
            </div>
            <div className="mt-2">
              <span className="fw-bold">Cách 2: </span>
              <span>
                Hoặc bạn có thể vào{" "}
                <span className="fw-bold">
                  {`Menu > Tài khoản > Mã giới thiệu`}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default Affiliate;

import React, { useContext, useEffect, useState } from "react";
import { KetquaContextPro } from "../../../context/KetquaContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./ketqua.css";
import { Button, Checkbox, InputPicker, Loader } from "rsuite";
import { sendGet } from "../../../axios/axiosClient";
import { useMedia } from "react-use";
import { Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment/moment";
import { FaShoppingBag } from "react-icons/fa";
import Footer from "../../../layout/footer/Footer";
import Laso from "../../la-so/Laso";
import Preview from "./preview/Preview";
import Detail from "./detail/Detail";
import logo from "../../../assets/images/logo/logo-black.png";
import animal_1 from "../../../assets/images/animals/1.webp";
import animal_2 from "../../../assets/images/animals/2.webp";
import animal_3 from "../../../assets/images/animals/3.webp";
import animal_4 from "../../../assets/images/animals/4.webp";
import animal_5 from "../../../assets/images/animals/5.webp";
import animal_6 from "../../../assets/images/animals/6.webp";
import animal_7 from "../../../assets/images/animals/7.webp";
import animal_8 from "../../../assets/images/animals/8.webp";
import animal_9 from "../../../assets/images/animals/9.webp";
import animal_10 from "../../../assets/images/animals/10.webp";
import animal_11 from "../../../assets/images/animals/11.webp";
import animal_12 from "../../../assets/images/animals/12.webp";
import Heading from "../../../layout/heading-layout/Heading";
import zodiac_wheel from "../../../assets/images/home/zodiac-wheel.webp";
import { introduceTopic } from "../../../data/IntroduceTopic";

const Ketqua = () => {
  const { ketqua, setKetqua } = useContext(KetquaContextPro);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isWide = useMedia("(min-width: 783px)");
  const location = useLocation();
  const [firstTime, setFirstTime] = useState(true);
  const [formValue, setFormValue] = useState(
    JSON.parse(localStorage.getItem("ket-qua-params")) || {}
  );
  const colors = [
    "#FD475E",
    "#9EE4DA",
    "#F7EA8A",
    "#C788DE",
    "#A7D676",
    "#F8B7C9",
    "#FDBD3F",
    "#6EAF99",
    "#B7E5FC",
  ];
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "bar-chart",
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, "chart");
          // console.log(w, "w");
          // console.log(e, "e");
        },
      },
    },
    // title: {
    //   text:
    //     ketqua?.chart !== undefined && ketqua?.chart?.length
    //       ? ketqua?.chart?.conditions
    //         ? JSON.parse(ketqua?.chart?.conditions)
    //         : "" + ketqua?.chart?.conditions_vcd
    //         ? JSON.parse(ketqua?.chart?.conditions_vcd)
    //         : ""
    //       : "",
    // },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: isWide ? "16px" : "12px",
    },
    yaxis: {
      max: 100,
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: [
        "Sức mạnh Tự tin",
        "Sự Tinh tế",
        "Tích cực",
        "Cầu toàn",
        "Năng động",
        "Yêu thương",
        "Thích khám phá",
        "Sự công bằng",
        "Bao dung",
      ],
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return (
            '<div class="arrow_box wrapbox">' +
            introduceTopic.find((item) => item.type === dataPointIndex + 15)
              .content +
            "</div>"
          );
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Điểm số",
      data:
        ketqua?.is_unlocked === 1
          ? [
              ketqua?.chart?.confidence,
              ketqua?.chart?.sophistication,
              ketqua?.chart?.positivity,
              ketqua?.chart?.perfectionism,
              ketqua?.chart?.dynamism,
              ketqua?.chart?.love,
              ketqua?.chart?.exploration,
              ketqua?.chart?.concentration,
              ketqua?.chart?.tolerance,
            ]
          : [
              ketqua?.chart?.confidence,
              ketqua?.chart?.sophistication,
              ketqua?.chart?.positivity,
              ketqua?.chart?.perfectionism,
              0,
              0,
              0,
              0,
              0,
            ],
    },
  ]);
  const [circleChartSeries, setCircleChartSeries] = useState(
    ketqua?.chartCircle || []
  );
  const [circleChartOptions, setCircleChartOptions] = useState({
    chart: {
      id: "pie-chart",
      width: 380,
      type: "pie",
    },
    labels: ["Thổ", "Hỏa", "Thuỷ", "Mộc", "Kim"],
    colors: ["#F7CB10", "#B7090A", "#C3E9FC", "#B6DB8B", "#D9D9D9"],
    dataLabels: {
      enabled: false,
      // formatter: function (val, { seriesIndex, dataPointIndex, w }) {
      //   return w.config.series[seriesIndex];
      // },
    },
    legend: {
      position: "bottom",
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (ketqua === null) {
      // if (location?.pathname === "/laso" && location?.search !== "") {
      //   getKetqua(location?.search?.substring(1));
      // } else {
      // }
      getKetqua(formValue);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!firstTime) {
      getKetqua(formValue);
    } else {
      setFirstTime(false);
    }
  }, [formValue]);

  const getKetqua = async (input) => {
    setIsLoading(true);
    try {
      const res = await sendGet(`/laso`, input);
      if (res.status === 200) {
        const resData = res.data.data;
        setKetqua(resData);
        setFirstTime(false);
      }
    } catch (error) {
      navigate("/");
    }
    setIsLoading(false);
  };

  // handle print luan giai theo type
  const handleContent = () => {
    switch (location.pathname) {
      case "/ket-qua":
        return <Preview ketqua={ketqua} />;
      case "/detail":
        return (
          <Detail
            congiap={handleAnimal(ketqua?.congiap)}
            ketqua={ketqua}
            setKetqua={setKetqua}
            chartOptions={chartOptions}
            chartSeries={chartSeries}
            circleChartOptions={circleChartOptions}
            circleChartSeries={circleChartSeries}
          />
        );
      default:
        break;
    }
  };

  const handleAnimal = (animal) => {
    switch (animal) {
      case 1:
        return animal_1;
      case 2:
        return animal_2;
      case 3:
        return animal_3;
      case 4:
        return animal_4;
      case 5:
        return animal_5;
      case 6:
        return animal_6;
      case 7:
        return animal_7;
      case 8:
        return animal_8;
      case 9:
        return animal_9;
      case 10:
        return animal_10;
      case 11:
        return animal_11;
      case 12:
        return animal_12;
    }
  };

  useEffect(() => {
    if (ketqua !== null) {
      // console.log("set new value");
      setChartOptions({
        ...chartOptions,
        xaxis: {
          labels: {
            show: false,
          },
          categories: [
            "Sức mạnh Tự tin",
            "Sự Tinh tế",
            "Tích cực",
            "Cầu toàn",
            "Năng động",
            "Yêu thương",
            "Thích khám phá",
            "Sự công bằng",
            "Bao dung",
          ],
        },
      });
      setChartSeries([
        {
          name: "Điểm số",
          data:
            ketqua?.is_unlocked === 1
              ? [
                  ketqua?.chart?.confidence,
                  ketqua?.chart?.sophistication,
                  ketqua?.chart?.positivity,
                  ketqua?.chart?.perfectionism,
                  ketqua?.chart?.dynamism,
                  ketqua?.chart?.love,
                  ketqua?.chart?.exploration,
                  ketqua?.chart?.concentration,
                  ketqua?.chart?.tolerance,
                ]
              : [
                  ketqua?.chart?.confidence,
                  ketqua?.chart?.sophistication,
                  ketqua?.chart?.positivity,
                  ketqua?.chart?.perfectionism,
                  0,
                  0,
                  0,
                  0,
                  0,
                ],
        },
      ]);

      setCircleChartSeries([
        ketqua?.chartCircle[1],
        ketqua?.chartCircle[2],
        ketqua?.chartCircle[3],
        ketqua?.chartCircle[4],
        ketqua?.chartCircle[5],
      ]);
      setCircleChartOptions((old) => ({
        ...old,
        dataLabels: {
          enabled: ketqua?.paid,
        },
      }));
    }
  }, [ketqua]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    // window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {isLoading ? (
        <div className="background-loader">
          <div className="ket-qua-loading"></div>
        </div>
      ) : (
        <div className="ketqua-body">
          {isWide ? (
            <>
              <img
                loading="lazy"
                src={handleAnimal(ketqua?.congiap)}
                className="zodiac-animals animal-1"
              />
              {/* <img
                loading="lazy"
                src={handleAnimal(ketqua?.congiap)}
                className="zodiac-animals animal-2"
              />
              <img
                loading="lazy"
                src={zodiac_wheel}
                className="zodiac-spin"
                alt="zodiac-wheel"
              /> */}
            </>
          ) : (
            <></>
          )}
          <Heading />
          <div style={isWide ? { height: "5rem" } : {}}></div>
          {handleContent()}
          <div className="ket-qua-footer">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Ketqua;

export const introduceTopic = [
  {
    type: 0,
    content:
      "<p><em>Lục Thập Hoa Gi&aacute;p theo văn h&oacute;a người phương Đ&ocirc;ng th&igrave; được tạo th&agrave;nh bằng việc kết hợp 10 Thi&ecirc;n Can v&agrave; 12 Địa Chi trong Ngũ h&agrave;nh chỉ ra bản mệnh con người trong tứ trụ năm, th&aacute;ng, ng&agrave;y v&agrave; giờ. &ldquo;Lục Thập&rdquo; c&oacute; nghĩa l&agrave; 60, &ldquo;Hoa Gi&aacute;p&rdquo; c&oacute; nghĩa m&ugrave;a hoa nở, &yacute; chỉ chu kỳ vận h&agrave;nh của c&aacute;c con gi&aacute;p trong v&ograve;ng tuần ho&agrave;n cứ 60 năm sẽ lặp lại. Chu kỳ n&agrave;y tượng trưng cho sự lu&acirc;n chuyển v&agrave; lặp lại của thời gian, mang &yacute; nghĩa về sự sống v&agrave; t&aacute;i sinh của con người. Lục Thập Hoa Gi&aacute;p mang nội h&agrave;m rất s&acirc;u sắc, được ứng dụng để luận t&iacute;nh c&aacute;ch từng tuổi, dự đo&aacute;n vận tr&igrave;nh, t&iacute;nh vận hạn, xem ng&agrave;y tốt xấu&hellip;cho con người. Mỗi một Ngũ H&agrave;nh Nạp &Acirc;m ứng với mỗi tuổi mang theo trạng th&aacute;i &acirc;m dương kh&aacute;c nhau sẽ tạo n&ecirc;n một t&iacute;nh chất của bản mệnh kh&aacute;c nhau. T&igrave;m ra &yacute; nghĩa Ngũ H&agrave;nh Nạp &Acirc;m của bạn, từ đ&oacute; c&oacute; thể luận đo&aacute;n được một phần vận mệnh trong suốt cuộc đời như: c&ocirc;ng danh sự nghiệp ra sao, qu&yacute; nh&acirc;n đến từ đ&acirc;u, l&agrave;m sao để tr&aacute;nh những điều kh&ocirc;ng may mắn&hellip;</em></p>\n            ",
  },
  {
    type: 1,
    content:
      "<p><em>Cửu Diệu Tinh Qu&acirc;n c&oacute; nguồn gốc từ Đạo Gi&aacute;o, được quan niệm l&agrave; l&agrave; 9 vị thần tr&ocirc;ng coi 9 thi&ecirc;n thể trong bầu trời. Gồm Th&aacute;i Dương, Th&aacute;i &Acirc;m, Kim Tinh, Mộc Tinh, Hoả Tinh, Thổ Tinh, Thuỷ Tinh, Kế Đ&ocirc;, La Hầu Tinh Qu&acirc;n . Theo cổ nh&acirc;n truyền lại, mỗi tuổi của mỗi sinh mệnh h&agrave;ng năm sẽ do một trong 9 vị thần n&agrave;y cai quản. Mỗi vị thần lại đại diện cho một v&igrave; sao, mang theo những ảnh hưởng về vận tr&igrave;nh kh&aacute;c nhau. Thực chất l&agrave; n&oacute;i đến sự vận động của năng lượng Thi&ecirc;n Kh&iacute; ảnh hưởng đến con người với c&aacute;c đặc trưng kh&aacute;c nhau về &Acirc;m Dương/Ngũ H&agrave;nh. Trong mỗi c&aacute; thể đều c&oacute; T&acirc;m t&agrave;ng Kh&iacute;, Kh&iacute; của Trời Đất v&agrave; Kh&iacute; của T&acirc;m Người c&oacute; sự giao thoa cảm ứng, từ đ&oacute; h&igrave;nh th&agrave;nh n&ecirc;n vận tr&igrave;nh cuộc đời. Biết về &yacute; nghĩa của Sao Chiếu Bản Mệnh từng năm sẽ gi&uacute;p ta c&oacute; thể luận đo&aacute;n được những g&igrave; c&oacute; thể xảy ra trong năm ấy, từ đ&oacute; m&agrave; d&ugrave;ng t&acirc;m cảm ứng nghinh ph&uacute;c trừ tai.</em></p>\n            ",
  },
  {
    type: 6,
    content:
      "<p><em>Việc xem x&eacute;t vị tr&iacute; cung Mệnh tại c&aacute;c địa chi c&oacute; hai t&aacute;c dụng ch&iacute;nh. Thứ nhất, n&oacute; cho ph&eacute;p x&aacute;c định những n&eacute;t cơ bản trong c&aacute; t&iacute;nh của mỗi người. Cụ thể, căn cứ v&agrave;o ng&agrave;y, th&aacute;ng, năm sinh, c&oacute; thể ph&acirc;n t&iacute;ch những đặc điểm t&iacute;nh c&aacute;ch con người. Thứ hai, c&oacute; thể dung n&oacute; l&agrave;m cơ sở ban đầu để kiểm chứng v&agrave; so s&aacute;nh để l&agrave;m s&aacute;ng tỏ c&aacute;c th&ocirc;ng tin mơ hồ xung quanh ng&agrave;y th&aacute;ng năm sinh</em></p>\n            ",
  },
  {
    type: 3,
    content:
      "<p><em>Cung Mệnh l&agrave; cung quan trọng nhất trong 12 cung của Tử vi Đẩu Số. N&oacute; ảnh hưởng đến mệnh vận v&agrave; con người. Nhờ cung Mệnh, ch&uacute;ng ta c&oacute; thể đ&aacute;nh gi&aacute; về ngoại h&igrave;nh, t&iacute;nh c&aacute;ch, t&agrave;i năng v&agrave; cả vận may của một người. Cung Mệnh l&agrave; yếu tố ch&iacute;nh v&agrave; khi kết hợp với cung tam phương tứ ch&iacute;nh, sẽ quyết định về th&agrave;nh tựu v&agrave; mức độ th&agrave;nh c&ocirc;ng của một người. Nếu mệnh số ti&ecirc;n thi&ecirc;n (thi&ecirc;n bẩm) tốt nhưng vận thế k&eacute;m, c&oacute; thể sẽ gặp kh&oacute; khăn trong cuộc sống, tuy nhi&ecirc;n, c&oacute; thể nhờ v&agrave;o may mắn v&agrave; sự gi&uacute;p đỡ từ m&ocirc;i trường xung quanh, những kh&oacute; khăn n&agrave;y c&oacute; thể được vượt qua. Mặt kh&aacute;c, nếu mệnh số ti&ecirc;n thi&ecirc;n k&eacute;m nhưng vận thế tốt, ch&uacute;ng ta c&oacute; thể thay đổi số mệnh của m&igrave;nh th&ocirc;ng qua nỗ lực v&agrave; cố gắng của bản th&acirc;n. Điều quan trọng l&agrave; kh&ocirc;ng chỉ dựa v&agrave;o cung Mệnh để đ&aacute;nh gi&aacute; cuộc sống m&agrave; c&ograve;n cần xem x&eacute;t nhiều yếu tố kh&aacute;c như vận thế v&agrave; c&aacute;ch ch&uacute;ng ta l&agrave;m việc. Sự may mắn v&agrave; nỗ lực c&aacute; nh&acirc;n đều c&oacute; thể ảnh hưởng đến cuộc sống v&agrave; th&agrave;nh c&ocirc;ng của mỗi người</em></p>\n            ",
  },
  {
    type: 4,
    content:
      "<p><em>Vị tr&iacute; cung Th&acirc;n trong l&aacute; số tử vi cho biết lĩnh vực nổi bật v&agrave; quan trọng nhất trong cuộc sống của mỗi người. Cung Th&acirc;n trong tử vi kh&ocirc;ng c&oacute; vị tr&iacute; ri&ecirc;ng m&agrave; đ&oacute;ng v&agrave;o một trong c&aacute;c cung Mệnh, Ph&uacute;c, Quan, Di, T&agrave;i, Phối. Mỗi cung vị c&oacute; &yacute; nghĩa v&agrave; t&aacute;c động kh&aacute;c nhau đến cuộc sống của con người.</em></p>\n            ",
  },
  {
    type: 5,
    content:
      "<p>Lai Nh&acirc;n Cung l&agrave; một yếu tố m&agrave; kh&aacute;n bản mệnh m&agrave; rất &iacute;t phi&ecirc;n bản l&aacute; số tử vi đề cập tới. Nếu hiểu về vị tr&iacute; của Cung Th&acirc;n, sẽ biết được yếu tố b&ecirc;n trong t&aacute;c động đến bạn. C&ograve;n nếu hiểu về vị tr&iacute; của Lai Nh&acirc;n Cung th&igrave; ngược lại, bạn sẽ biết được yếu tố b&ecirc;n ngo&agrave;i n&agrave;o đang t&aacute;c động đến cuộc đời của bạn. Nếu &quot;chăm s&oacute;c&quot; tốt cho yếu tố thuộc về Lai Nh&acirc;n Cung c&oacute; thể bổ trợ cho vận tr&igrave;nh cuộc sống của bạn th&ecirc;m phần thuận lợi. V&agrave; ch&iacute;nh bạn cũng học được b&agrave;i học cần phải ch&uacute; t&acirc;m v&agrave;o điểm quan trọng n&agrave;o tr&ecirc;n h&agrave;nh tr&igrave;nh tiến bước tr&ecirc;n đường đời. Lai Nh&acirc;n Cung được v&iacute; như mối nh&acirc;n duy&ecirc;n kiếp trước, cho tới kiếp n&agrave;y nối liền, gi&uacute;p bạn thấu hiểu nh&acirc;n sinh, ngộ ra nguy&ecirc;n nh&acirc;n của những th&aacute;ch thức v&agrave; từ đ&oacute; c&oacute; thể t&igrave;m ra hướng đi s&aacute;ng suốt hơn v&agrave; cải thiện chất lượng đời sống tinh thần v&agrave; vật chất.</p>\n\n            <p>C&oacute; 12 mối nh&acirc;n duy&ecirc;n tồn tại ứng với 12 cung ch&iacute;nh tr&ecirc;n l&aacute; số Tử Vi, vậy bạn đang c&oacute; kết nối s&acirc;u sắc với mối nh&acirc;n duy&ecirc;n n&agrave;o ?</p>\n            ",
  },
  {
    type: 100,
    content:
      "<p>Đây là 9 nhóm tính cách thuộc về bản ngã của mỗi người. Tính cách này được coi là phiên bản nguyên gốc, là những gì thuộc về bạn ngay từ khi bạn sinh ra. Bạn sẽ biết điểm mạnh và điểm yếu với từng nhóm tính cách cụ thể, điều này sẽ giúp bạn hiểu hơn về những đặc điểm bên trong con người của mình. Thông qua sự hiểu biết này, bạn có thể hình dung rõ ràng hơn về những thế mạnh vượt trội mà bản thân sở hữu và những điểm hạn chế cần cải thiện. </p>",
  },
  {
    type: 7,
    content:
      "<p>Bạn th&acirc;n mến, đ&acirc;y l&agrave; những kh&iacute;a cạnh c&oacute; biến động lớn nhất trong năm 2023 của bạn.</p>\n\n            <p>H&atilde;y c&ugrave;ng xem lại vận tr&igrave;nh năm 2023 của bạn đ&atilde; c&oacute; điều g&igrave; diễn ra nh&eacute; !</p>\n            ",
  },
  {
    type: 8,
    content:
      "<p>Bạn th&acirc;n mến, đ&acirc;y l&agrave; những kh&iacute;a cạnh c&oacute; biến động lớn nhất trong năm 2024 của bạn.</p>\n\n            <p>H&atilde;y c&ugrave;ng xem vận tr&igrave;nh năm 2024 của bạn sẽ c&oacute; điều g&igrave; diễn ra nh&eacute; !</p>\n            ",
  },
  {
    type: 9,
    content:
      "<p>Hãy cùng xem vận trình trong 3 tháng sắp tới của bạn sẽ có điều gì diễn ra nhé !</p>\n            ",
  },
  {
    type: 24,
    content:
      "<p>H&atilde;y c&ugrave;ng xem vận tr&igrave;nh trong 3 th&aacute;ng sắp tới của bạn sẽ c&oacute; điều g&igrave; diễn ra nh&eacute; !</p>\n            ",
  },
  {
    type: 10,
    content:
      "<p><em>Một số điểm nổi bật về c&aacute; t&iacute;nh, t&agrave;i năng, năng lực, ưu- nhược điểm của bản th&acirc;n</em></p>\n            ",
  },
  {
    type: 11,
    content:
      "<p><em>Một v&agrave;i lĩnh vực c&ocirc;ng việc ph&ugrave; hợp với c&aacute; t&iacute;nh v&agrave; t&agrave;i năng của bạn</em></p>\n            ",
  },
  {
    type: 12,
    content:
      "<p><em>Đặc điểm nổi bật trong vấn đề t&igrave;nh cảm</em></p>\n            ",
  },
  {
    type: 13,
    content:
      "<p><em>Một v&agrave;i lời khuy&ecirc;n để bạn cải thiện v&agrave; ph&aacute;t triển hơn trong tương lai</em></p>\n            ",
  },
  {
    type: 14,
    content: `<p>Âm dương và ngũ hành là hai yếu tố cơ bản tạo nên sự tồn tại và phát triển của vạn vật, trong đó con người được quyết định bởi "bản mệnh" gồm Ngũ hành Kim, Mộc, Thủy, Hỏa, Thổ với tỷ lệ khác nhau. Mỗi hành mang những đặc tính riêng và tác động đến các khía cạnh sống của con người. Bên cạnh đó, "thiên tính" của con người cũng có mối liên hệ mật thiết với ngũ hành, thể hiện ở hai mặt vượng (nổi bật) và nhược ( thiếu, ẩn chìm). Khi biết được Bát Tự khuyết hoặc vượng yếu tố ngũ hành nào, bạn có thể ứng dụng những phương pháp cải vận đơn giản để cân bằng bản mệnh, từ đó  bạn có thể biến hóa bản thân trở nên vững vàng, mạnh mẽ hơn, gia tăng vượng khí, thu hút may mắn.
 
      </p>`,
  },
  {
    type: 15,
    content:
      "<p>Nh&oacute;m n&agrave;y bao gồm c&aacute;c t&iacute;nh c&aacute;ch mạnh mẽ, độc lập v&agrave; tự tin. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; một tinh thần mạnh mẽ, d&aacute;m nghĩ d&aacute;m l&agrave;m, tự tin trong quyết định v&agrave; h&agrave;nh động của m&igrave;nh.</p>\n            ",
  },
  {
    type: 16,
    content:
      "<p>Nh&oacute;m n&agrave;y gồm c&aacute;c t&iacute;nh c&aacute;ch lắng nghe, kh&eacute;o l&eacute;o v&agrave; nhạy cảm. Những người thuộc nh&oacute;m n&agrave;y c&oacute; khả năng lắng nghe v&agrave; hiểu người kh&aacute;c, biết c&aacute;ch tương t&aacute;c một c&aacute;ch kh&eacute;o l&eacute;o v&agrave; nhạy b&eacute;n với cảm x&uacute;c của mọi người xung quanh.</p>\n            ",
  },
  {
    type: 17,
    content:
      "<p>Nh&oacute;m n&agrave;y bao gồm c&aacute;c t&iacute;nh c&aacute;ch s&aacute;ng tạo, hoạt b&aacute;t v&agrave; lạc quan. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; sự s&aacute;ng tạo, năng lượng t&iacute;ch cực v&agrave; niềm tin v&agrave;o tiềm năng của cuộc sống. Họ c&oacute; thể tạo ra &yacute; tưởng mới, tư duy linh hoạt v&agrave; lu&ocirc;n c&oacute; tinh thần lạc quan.</p>\n            ",
  },
  {
    type: 18,
    content:
      "<p>Nh&oacute;m n&agrave;y gồm c&aacute;c t&iacute;nh c&aacute;ch cẩn thận, cầu to&agrave;n v&agrave; thực tế. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; t&iacute;nh cẩn thận, đặt sự ch&iacute;nh x&aacute;c v&agrave; chất lượng l&ecirc;n h&agrave;ng đầu. Họ c&oacute; xu hướng t&igrave;m hiểu kỹ lưỡng v&agrave; l&agrave;m việc theo những quy tắc v&agrave; hiện thực.</p>\n            ",
  },
  {
    type: 19,
    content:
      "<p>Nh&oacute;m n&agrave;y bao gồm c&aacute;c t&iacute;nh c&aacute;ch năng động, linh hoạt v&agrave; t&ograve; m&ograve;. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; động lực cao, khả năng th&iacute;ch ứng nhanh ch&oacute;ng v&agrave; sự t&ograve; m&ograve; kh&aacute;m ph&aacute; thế giới xung quanh. Họ c&oacute; thể dễ d&agrave;ng th&iacute;ch nghi với những t&igrave;nh huống mới v&agrave; đ&oacute;n nhận th&aacute;ch thức.</p>\n            ",
  },
  {
    type: 20,
    content:
      "<p>Nh&oacute;m n&agrave;y gồm c&aacute;c t&iacute;nh c&aacute;ch quan t&acirc;m, y&ecirc;u thương v&agrave; kiểm so&aacute;t. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; khả năng quan t&acirc;m ch&acirc;n th&agrave;nh đến người kh&aacute;c, hiểu v&agrave; chia sẻ t&igrave;nh y&ecirc;u thương. Họ cũng c&oacute; khả năng kiểm so&aacute;t v&agrave; quản l&yacute; t&igrave;nh huống một c&aacute;ch tử tế v&agrave; c&acirc;n nhắc.</p>\n            ",
  },
  {
    type: 21,
    content:
      "<p>Nh&oacute;m n&agrave;y bao gồm c&aacute;c t&iacute;nh c&aacute;ch th&ocirc;ng th&aacute;i, kh&aacute;m ph&aacute; v&agrave; truyền đạt. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; sự th&ocirc;ng th&aacute;i, tinh tế trong nh&igrave;n nhận cuộc sống v&agrave; kh&aacute;m ph&aacute; những điều mới mẻ. Họ c&oacute; khả năng truyền đạt th&ocirc;ng điệp một c&aacute;ch hiệu quả v&agrave; thấu hiểu cảm x&uacute;c của người kh&aacute;c.</p>\n            ",
  },
  {
    type: 22,
    content:
      "<p>Nh&oacute;m n&agrave;y gồm c&aacute;c t&iacute;nh c&aacute;ch c&ocirc;ng bằng, tập trung v&agrave; l&yacute; tưởng. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; l&ograve;ng c&ocirc;ng bằng, đề cao sự c&ocirc;ng l&iacute; v&agrave; tập trung v&agrave;o mục ti&ecirc;u cụ thể. Họ c&oacute; khả năng tập trung cao độ v&agrave; đặt những l&yacute; tưởng cao trong cuộc sống.</p>\n            ",
  },
  {
    type: 23,
    content:
      "<p>Nh&oacute;m n&agrave;y bao gồm c&aacute;c t&iacute;nh c&aacute;ch tr&aacute;ch nhiệm, rộng lượng v&agrave; bao dung. Những người thuộc nh&oacute;m n&agrave;y thường c&oacute; &yacute; thức tr&aacute;ch nhiệm, sẵn l&ograve;ng gi&uacute;p đỡ v&agrave; chia sẻ với người kh&aacute;c. Họ c&oacute; khả năng quan t&acirc;m đến lợi &iacute;ch chung v&agrave; sẵn l&ograve;ng đ&oacute;ng g&oacute;p cho x&atilde; hội v&agrave; cộng đồng.</p>\n            ",
  },
];

import { useEffect, useState } from "react";
import { sendDelete, sendGet, sendPost } from "../../../axios/axiosClient";
import { Panel, Button, Modal, Message, useToaster, Input } from "rsuite";
import Table from "react-bootstrap/Table";
import { Chip } from "@mui/material";

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [createCouponModal, setCreateCouponModal] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const toaster = useToaster();
  const placement = "topCenter";

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );
  const handleGetCoupons = async () => {
    try {
      const res = await sendGet("/admin/coupon", {});
      console.log(res);
      if (res) {
        setCoupons(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteExpiredCoupon = async () => {
    try {
      const res = await sendDelete("/admin/coupon", {});
      console.log(res);
      if (res) {
        setTextMessage("Xoá thành công");
        handleGetCoupons();
      }
    } catch (error) {
      console.log(error);
      setTextMessage("Xoá thất bại");
    }
  };
  const handleCreateCoupon = async () => {
    try {
      const res = await sendPost("/admin/coupon", {
        amount: document.getElementById("amount").value,
      });
      console.log(res);
      if (res) {
        setCreateCouponModal(false);
        setTextMessage("Tạo thành công");
        handleGetCoupons();
      }
    } catch (error) {
      console.log(error);
      // setTextMessage(error?.response?.data?.message);
      setCreateCouponModal(false);
    }
  };
  useEffect(() => {
    handleGetCoupons();
  }, []);

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  return (
    <div>
      <Modal
        open={createCouponModal}
        onClose={() => setCreateCouponModal(false)}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Tạo bao nhiêu coupon ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input id="amount" type="number" placeholder="--" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreateCoupon} appearance="primary">
            Tạo
          </Button>
          <Button
            onClick={() => setCreateCouponModal(false)}
            appearance="subtle"
          >
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end">
          <div>
            <Button
              appearance="primary"
              color="red"
              onClick={handleDeleteExpiredCoupon}
            >
              Xoá
            </Button>
            <Button
              className="ms-2"
              appearance="primary"
              color="green"
              onClick={() => {
                setCreateCouponModal(true);
              }}
            >
              Tạo
            </Button>
          </div>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Mã</th>
              <th>Ngày tạo</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {coupons?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx}</td>
                <td>{item?.code}</td>
                <td>{item?.created_at}</td>
                <td>
                  <Chip
                    sx={{ marginLeft: "auto" }}
                    label={item?.status_text}
                    size="small"
                    color={
                      item?.status_text === "Còn hạn" ? "success" : "error"
                    }
                  ></Chip>
                </td>
                {/* <td>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Xoá
                  </Button>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="yellow"
                    onClick={() => {
                      setId(item?.id);
                      formik.setFieldValue("user_id", item?.user_id);
                      formik.setFieldValue("cost", item?.cost);
                      formik.setFieldValue("service_id", item?.service_id);
                      formik.setFieldValue(
                        "payment_method",
                        item?.payment_method
                      );
                      formik.setFieldValue("status", item?.status);
                      setUpdateModal(true);
                    }}
                  >
                    Cập Nhật
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        {/* {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )} */}
      </Panel>
    </div>
  );
};
export default Coupon;

import React, { createContext, useRef, useState } from "react";

export const KetquaContextPro = createContext({});

const KetquaContext = ({ children }) => {
  const [ketqua, setKetqua] = useState(null);
  const btnRef = useRef(null);

  return (
    <KetquaContextPro.Provider value={{ ketqua, setKetqua, btnRef }}>
      {children}
    </KetquaContextPro.Provider>
  );
};

export default KetquaContext;

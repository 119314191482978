import React from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputPicker,
  Message,
  Modal,
  Pagination,
  Panel,
  useToaster,
} from "rsuite";
import Table from "react-bootstrap/Table";
import { AiOutlineSearch } from "react-icons/ai";
import ReactQuill from "react-quill";

const Category = () => {
  const [listCategories, setListCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setFormValue({
      name: "",
      description: "",
      status: 0,
    });
  };
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [formValue, setFormValue] = useState({
    status: 0,
    description: "",
    name: "",
  });
  const [formUpdate, setFormUpdate] = useState({
    status: 0,
    description: "",
    name: "",
  });

  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const statusData = [
    {
      value: 0,
      label: "Active",
    },
    {
      value: 1,
      label: "Inactive",
    },
  ];

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListCategory = async () => {
    try {
      const input = {
        page: page,
        page_size: limit,
      };
      const res = await sendGet(`/admin/categories`, input);
      if (res.status === 200) {
        setListCategories(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {}
  };

  const handleCreate = async () => {
    try {
      const input = new FormData();
      input.append("name", formValue.name);
      input.append("description", formValue.description);
      input.append("status", formValue.status);
      const res = await sendPost(`/admin/categories`, input);
      if (res.status === 200) {
        setTextMessage("Tạo mới thành công.");
        getListCategory();
        handleCloseCreate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleUpdate = async () => {
    try {
      const res = await sendPut(
        `/admin/categories/${formUpdate?.id}`,
        formUpdate
      );
      if (res.status === 200) {
        setTextMessage("Cập nhật thành công.");
        getListCategory();
        handleCloseUpdate();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`admin/categories/${id}`);
      if (res.status === 200) {
        setTextMessage("Xoá category thành công.");
        getListCategory();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListCategory();
  }, [page, limit]);

  return (
    <div>
      <Modal open={openCreate} onClose={handleCloseCreate} backdrop="static">
        <Modal.Header>
          <Modal.Title>TẠO MỚI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid formValue={formValue} onChange={setFormValue}>
            <Form.Group>
              <Form.ControlLabel>Tên</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Mô tả</Form.ControlLabel>
              {/* <Form.Control name="description" type="text" /> */}
              <ReactQuill
                value={formValue?.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    description: e,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control
                name="status"
                className="w-100"
                accepter={InputPicker}
                data={statusData}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCreate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseCreate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openUpdate} onClose={handleCloseUpdate} backdrop="static">
        <Modal.Header>
          <Modal.Title>CẬP NHẬT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid formValue={formUpdate} onChange={setFormUpdate}>
            <Form.Group>
              <Form.ControlLabel>Tên</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Mô tả</Form.ControlLabel>
              {/* <Form.Control name="description" type="text" /> */}
              <ReactQuill
                value={formUpdate?.description}
                onChange={(e) =>
                  setFormUpdate({
                    ...formUpdate,
                    description: e,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.ControlLabel>Trạng thái</Form.ControlLabel>
              <Form.Control
                name="status"
                className="w-100"
                accepter={InputPicker}
                data={statusData}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleUpdate} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseUpdate} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>XOÁ</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá thư mục này không?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end gap-2">
          <div>
            <InputGroup>
              <Input placeholder={"Search"} />
              <InputGroup.Addon>
                <AiOutlineSearch />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <Button appearance="primary" onClick={handleOpenCreate}>
            Thêm mới
          </Button>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Tên</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listCategories?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx}</td>
                <td>{item?.name}</td>
                <td>{item?.description}</td>
                <td>{item?.status ? "Tạm dừng" : "Hoạt động"}</td>
                <td>
                  <Button
                    className="ms-2"
                    onClick={() => {
                      setFormUpdate(item);
                      handleOpenUpdate();
                    }}
                  >
                    Sửa
                  </Button>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Xoá
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Category;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Checkbox,
  Form,
  Loader,
  Message,
  Panel,
  useToaster,
} from "rsuite";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sendGet, sendPost } from "../../../axios/axiosClient";
import Cookies from "js-cookie";
import { InfoContextProvider } from "../../../context/InfoContext";

const Login = () => {
  const { info, setInfo } = useContext(InfoContextProvider);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  const type = "warning";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const res = await sendPost(`/login`, formValue);
      if (res?.status === 200) {
        Cookies.set("token", res.data.data.token);
        setInfo(res.data?.data?.user);
        if (location.search) {
          if (location.search.substring(1) === "horos") {
            navigate("/detail");
          } else {
            getOrder();
          }
        } else {
          setTextMessage("Đăng nhập thành công.");
          if (res.data.data.user?.role === 1) {
            navigate("/admin-home");
          } else {
            navigate("/");
          }
        }
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
    setIsLoading(false);
  };

  const getOrder = async () => {
    try {
      const res = await sendPost(
        `/user/orders?${location.search.substring(1)}`
      );
      if (res.status === 200) {
        window.location.assign(res.data.data);
      }
    } catch (error) {
      setTextMessage("Có lỗi xảy ra với cổng thanh toán, thử lại sau.");
      navigate("/");
    }
  };

  // console.log(location.search.substring(1));

  return (
    <div className="login">
      <div className="login-absolute">
        <div className="loginBody">
          <Link to={"/"}>
            <div className="page-logo"></div>
          </Link>
          <div className="login-heading"></div>
          <Form fluid formValue={formValue} onChange={setFormValue}>
            <Form.Group controlId="email">
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                style={{ borderRadius: "2rem" }}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                style={{ borderRadius: "2rem" }}
                placeholder="Mật khẩu"
                name="password"
                type="password"
                autoComplete="off"
              />
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Checkbox>Ghi nhớ đăng nhập</Checkbox>
              <Link className="forgot-password-link" to={"/forgot-password"}>
                Quên mật khẩu
              </Link>
            </div>
            <Form.Group>
              <ButtonToolbar>
                <Button
                  appearance="default"
                  className="login-button"
                  onClick={handleLogin}
                >
                  {isLoading ? <Loader /> : "Đăng nhập"}
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <span>
              Bạn chưa có tài khoản?
              <Link
                to={`/register${location?.search}`}
                className="register-link"
              >
                ĐĂNG KÝ NGAY
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import "./order.css";
import { sendDelete, sendGet, sendPut } from "../../../axios/axiosClient";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Input,
  InputGroup,
  Message,
  Modal,
  Pagination,
  Panel,
  useToaster,
} from "rsuite";
import { 
  Modal as ModalMui,
  Typography,
  TextField,
  Grid,
  Autocomplete,
  Chip
} from "@mui/material";
import Table from "react-bootstrap/Table";
import { AiOutlineSearch } from "react-icons/ai";
import { useFormik } from "formik";

const Order = () => {
  const [listOrder, setListOrder] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [updateModal, setUpdateModal] = useState(false);

  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue1, setInputValue1] = useState("");
  const formik = useFormik({
    initialValues: {
      status: "",
      payment_method: "",
      service_id: "",
      cost: "",
      user_id: "",
    }
  })
  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    // height: "700px",
    boxShadow: "24",
    borderRadius: "15px",
    backgroundColor: "#f1f5f8",
    // padding: "30px 100px 0px 100px",
    padding: "20px",
  }

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getListOrder = async () => {
    try {
      const input = {
        page: page,
        page_size: limit,
      };
      const res = await sendGet(`/admin/orders`, input);
      if (res.status === 200) {
        setListOrder(res.data.data.data);
        setTotalItems(res.data.data.total);
      }
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`admin/orders/${id}`);
      if (res.status === 200) {
        setTextMessage("Xoá thành công.");
        getListOrder();
        handleCloseDelete();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };
  const handleUpdateOrder = async () => {
    try{
      const input = {
        user_id: formik.values.user_id,
        cost: formik.values.cost,
        service_id: formik.values.service_id,
        payment_method: formik.values.payment_method,
        status: formik.values.status,
      }
      console.log(input);
      const res = await sendPut(`/admin/orders/${id}`, input);
      if(res.status === 200){
        setTextMessage("Cập nhật thành công.");
        getListOrder();
        setUpdateModal(false);
      }
    }catch(error){
      console.log(error)
      setTextMessage(error?.response?.data?.error_msg);
    }
  }

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListOrder();
  }, [limit, page]);
  const optionsStatus = [
    { label: 'Chưa Thanh Toán', value: 0 },
    { label: 'Đã Thanh Toán', value: 1 },
    { label: 'Đã Huỷ', value: 2 },
  ];
  const optionsMethod = [
    { label: 'VNPAY', value: "VNPAY" },
    { label: 'AUTOBANK', value: "AUTOBANK" },
  ];
  return (
    <div>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>XOÁ</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá đơn hàng này?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalMui 
        open={updateModal} 
        onClose={() => setUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
        <Typography variant="h6" component="div" gutterBottom>
          Cập nhật đơn hàng
        </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                placeholder="User ID"
                required
                id="user_id"
                fullWidth
                value={formik.values.user_id}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Giá tiền"
                required
                id="cost"
                fullWidth
                value={formik.values.cost}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="service_id"
                placeholder="ID Gói"
                fullWidth
                value={formik.values.service_id}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={optionsMethod.find(option => option.value === formik.values.payment_method) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue('payment_method', newValue?.value );
                }}
                inputValue={inputValue1}
                onInputChange={(event, newInputValue) => {
                  setInputValue1(newInputValue);
                }}
                id="payment_method"
                options={optionsMethod}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={optionsMethod.find(option => option.value === formik.values.payment_method)?.label || "Phương thức thanh toán"}
                    aria-label="Controllable"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={optionsStatus.find(option => option.value === formik.values.status) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue('status', newValue?.value );
                }}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                  setInputValue2(newInputValue);
                }}
                id="status"
                options={optionsStatus}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={optionsStatus.find(option => option.value === formik.values.status)?.label || "Trạng thái đơn hàng"}
                    aria-label="Controllable"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" color="primary" onClick={() => {handleUpdateOrder()}}>
                Cập Nhật
              </Button>
              <Button className="ms-2" appearance="primary" color="red" onClick={() => setUpdateModal(false)}>
                Huỷ
              </Button>
            </Grid>
          </Grid>
        </div>
      </ModalMui>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end">
          <div>
            <InputGroup>
              <Input placeholder={"Search"} />
              <InputGroup.Addon>
                <AiOutlineSearch />
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Mã ngân hàng</th>
              <th>Ngân hàng</th>
              <th>Mã đơn hàng</th>
              <th>Giá</th>
              <th>Phải trả</th>
              <th>Phương thức thanh toán</th>
              <th>Dịch vụ</th>
              <th>Mã giao dịch</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listOrder?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx}</td>
                <td>{item?.bank_code}</td>
                <td>{item?.bank}</td>
                <td>{item?.code}</td>
                <td>{item?.cost}</td>
                <td>{item?.total_paid}</td>
                <td>{item?.payment_method}</td>
                <td>{item?.service}</td>
                <td>{item?.transaction_code}</td>
                {/* <td>{item?.status ? "Chưa thanh toán" : "Đã thanh toán"}</td> */}
                <td>
                  <Chip
                      sx={{ marginLeft: 'auto' }}
                      label={item?.status === 0 ? "Chưa thanh toán" : 
                            (item?.status === 1 ? "Đã thanh toán" : "Đã huỷ")}
                      size="small"
                      color={item?.status === 0 ? "warning" : 
                            (item?.status === 1 ? "success" : "error")}
                  ></Chip>
                </td>
                <td>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Xoá
                  </Button>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="yellow"
                    onClick={() => {
                      setId(item?.id);
                      formik.setFieldValue('user_id', item?.user_id);
                      formik.setFieldValue('cost', item?.cost);
                      formik.setFieldValue('service_id', item?.service_id);
                      formik.setFieldValue('payment_method', item?.payment_method);
                      formik.setFieldValue('status', item?.status);
                      setUpdateModal(true);
                    }}
                  >
                    Cập Nhật
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Order;

import React from "react";
import "./user.css";
import { Button, IconButton } from "rsuite";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import mobile_logo from "../../assets/images/logo/logo-tuvihangngay-đen.png";
import Cookies from "js-cookie";
const UserAside = ({ isShowAside, setIsShowAside }) => {
  const navigate = useNavigate();
  const handleCloseAside = () => {
    setIsShowAside(false);
  };

  const link = [
    {
      to: "/",
      label: "Trang chủ",
    },
    {
      to: "/ket-qua",
      label: "Luận giải",
    },
    {
      to: "/detail",
      label: "Luận giải chi tiết",
    },
    {
      to: Cookies.get("token") ? "/user" : "/login",
      label: Cookies.get("token") ? "Thông tin người dùng" : "Đăng nhập",
    },
    // {
    //   to: "/ket-qua",
    //   label: "Giao diện lá số",
    // },
  ];

  const handleNavigate = (to) => {
    handleCloseAside();
    navigate(to);
  };

  return (
    <div className={`userAside${isShowAside ? "" : " closeAside"}`}>
      <div className="d-flex justify-content-between p-3">
        <div>
          <img
            src={mobile_logo}
            className="mobile-logo"
            alt="tu-vi-thien-an-logo"
          />
        </div>
        <AiOutlineCloseCircle
          className="closeButton"
          onClick={handleCloseAside}
        />
      </div>
      <div className="userAsideBody">
        {link?.map((item, idx) => (
          <p key={idx} onClick={() => handleNavigate(item?.to)}>
            {item?.label}
          </p>
        ))}
        {/* {Cookies.get("token") ? (
          <p onClick={() => handleNavigate("/user")}>Thông tin người dùng</p>
        ) : (
          <p onClick={() => handleNavigate("/login")}>Đăng nhập</p>
        )} */}
      </div>
    </div>
  );
};

export default UserAside;

import React, { useState } from "react";
import route from "./route";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import "./admin/admin.css";
import "./user/user.css";
import { useMedia } from "react-use";
import UserAside from "./user/UserAside";
import UserHeading from "./user/UserHeading";
import { BiSolidUserCircle } from "react-icons/bi";
import Cookies from "js-cookie";
import { ButtonToolbar, Dropdown, IconButton, Popover, Whisper } from "rsuite";

const User = () => {
  const isWide = useMedia("(min-width: 600px)");
  const [isShowAside, setIsShowAside] = useState(false);

  return (
    <>
      {isWide ? (
        <></>
      ) : (
        <>
          {isShowAside ? (
            <div
              className="overlay"
              onClick={() => setIsShowAside(false)}
            ></div>
          ) : (
            <></>
          )}
          <UserHeading
            isShowAside={isShowAside}
            setIsShowAside={setIsShowAside}
          />
          <UserAside
            isShowAside={isShowAside}
            setIsShowAside={setIsShowAside}
          />
        </>
      )}
    </>
  );
};

export default User;

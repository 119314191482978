import React, { useContext, useEffect, useState } from "react";
import "./detail.css";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Laso from "../../../la-so/Laso";
import hoa_giap from "../../../../assets/images/text-button/luc-thap-hoa-giap.png";
import cuu_dieu_tinh_quan from "../../../../assets/images/text-button/cuu-dieu-tinh-quan.png";
import thong_tin_ban_menh from "../../../../assets/images/text-button/thong-ting-ban-menh.png";
import bat_tu_cai_van from "../../../../assets/images/text-button/bat-tu-cai-van.png";
import tinh_cach from "../../../../assets/images/text-button/nang-luc-tinh-cach.png";
import cung_menh from "../../../../assets/images/text-button/tu-vi-cung-menh.png";
import cung_than from "../../../../assets/images/text-button/tu-vi-cung-than.png";
import lai_nhan_cung from "../../../../assets/images/text-button/tu-vi-lai-nhan-cung.png";
import gio_sinh from "../../../../assets/images/text-button/tu-vi-gio-sinh.png";
import van_han_2023 from "../../../../assets/images/text-button/tu-vi-van-han-2023.png";
import van_han_2024 from "../../../../assets/images/text-button/tu-vi-van-han-2024.png";
import van_han_thang from "../../../../assets/images/text-button/tu-vi-van-han-thang.png";
import smaller from "../../../../assets/images/smaller-heading.png";
import cong_viec from "../../../../assets/images/text-button/cuu-dieu-tinh-quan.png";
// import tinh_yeu from "../../../../assets/images/text-button/cuu_dieu_tinh_quan.webp";
// import loi_khuyen from "../../../../assets/images/text-button/cuu_dieu_tinh_quan.webp";
// import bat_tu from "../../../../assets/images/text-button/cuu_dieu_tinh_quan.webp";
import { sendGet, sendPost } from "../../../../axios/axiosClient";
import { Button, Loader, Message, Modal, useToaster, Input } from "rsuite";
import { Modal as ModalMui } from "@mui/material";
import { InfoContextProvider } from "../../../../context/InfoContext";
import { introduceTopic } from "../../../../data/IntroduceTopic";
import { useMedia } from "react-use";
import zodiac_animals_wheel from "../../../../assets/images/home/zodiac-wheel.webp";
import Cookies from "js-cookie";
import { KetquaContextPro } from "../../../../context/KetquaContext";
import FileSaver, { saveAs } from "file-saver";
import Autobank from "../../auto-bank/AutoBank";

const Detail = ({
  congiap,
  ketqua,
  setKetqua,
  chartOptions,
  chartSeries,
  circleChartOptions,
  circleChartSeries,
}) => {
  // useEffect(() => {
  //   console.log("ketqua", ketqua);
  //   console.log("chartSeries", chartSeries);
  //   console.log("circleChartOptions", circleChartOptions);
  //   console.log("chartSeries", chartOptions);
  //   console.log("circleChartOptions", circleChartSeries);
  // }, []);
  const { info, setInfo } = useContext(InfoContextProvider);
  const { btnRef } = useContext(KetquaContextPro);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openPNL, setOpenPNL] = React.useState(false);
  const handleOpenPNL = () => setOpenPNL(true);
  const handleClosePNL = () => setOpenPNL(false);
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const isWide = useMedia("(min-width: 600px)");
  const [isDownload, setIsDownload] = useState(false);
  const [linkPdf, setLinkPdf] = useState();
  const [autoBankModal, setAutoBankModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState("");
  const [intervalId, setIntervalId] = useState(0);
  const [couponModal, setCouponModal] = useState(false);
  const acbQR = `https://apiqr.web2m.com/api/generate/ACB/12515801/PHUNGPHUONGNAM?amount=${amount}&memo=TUVITHIENAN ${code}&is_mask=0&bg=0`;
  // console.log("info", info);

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handlePDF = async () => {
    try {
      const res = await sendGet(`/pdf`);
      FileSaver.saveAs(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLuangiai = (type) => {
    // array item have type is "type"
    const mapArr = ketqua?.luangiai?.filter((item) => item?.type === type);
    return (
      <div className="luan-giai-body">
        <img
          src={handleTitle(type)}
          loading="lazy"
          alt=""
          className="luan-giai-title-image"
        />
        <div
          className="luan-giai-introduce"
          dangerouslySetInnerHTML={{
            __html: introduceTopic.find((x) => x.type === type)?.content,
          }}
        ></div>
        <div className="luan-giai-content">
          {(() => {
            switch (type) {
              case 3:
                if (mapArr.length > 0) {
                  return mapArr?.map((item, idx) =>
                    ketqua?.is_unlocked === 1 ? (
                      <div key={idx}>
                        <p className="fw-bold text-center mb-2 luan-giai-heading">
                          {item?.title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.content_take_fee !== null
                                ? item?.content_take_fee
                                : item?.content,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div key={idx}>
                        <p className="fw-bold text-center mb-2 luan-giai-heading">
                          {item?.title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                        {idx + 1 === mapArr.length ? (
                          <>
                            <p className="fw-bold mt-2 mb-0">
                              Công danh, sự nghiệp:
                            </p>
                            <Link
                              to={"/confirm-payment"}
                              className="text-danger mt-2"
                            >
                              Bạn cần nâng cấp tài khoản VIP để xem được luận
                              giải này !
                            </Link>
                            <p className="fw-bold mt-2 mb-0">
                              Gia đạo, tình duyên:
                            </p>
                            <Link
                              to={"/confirm-payment"}
                              className="text-danger mt-2"
                            >
                              Bạn cần nâng cấp tài khoản VIP để xem được luận
                              giải này !
                            </Link>
                            <p className="fw-bold mt-2 mb-0">Lời khuyên:</p>
                            <Link
                              to={"/confirm-payment"}
                              className="text-danger  mt-2"
                            >
                              Hãy nâng cấp tài khoản VIP để xem được lời khuyên
                              cho bản mệnh !
                            </Link>
                          </>
                        ) : (
                          <Link
                            to={"/confirm-payment"}
                            className="text-danger text-center mt-2"
                          >
                            Bạn cần nâng cấp tài khoản VIP để xem được luận giải
                            này !
                          </Link>
                        )}
                      </div>
                    )
                  );
                } else {
                  return (
                    <div className="vip-notice-content">
                      <Link
                        to={"/confirm-payment"}
                        className="text-danger text-center mt-2"
                      >
                        Bạn cần nâng cấp tài khoản VIP để xem được luận giải này
                        !
                      </Link>
                    </div>
                  );
                }
              case 8:
                if (ketqua?.is_unlocked === 1) {
                  return mapArr?.map((item, idx) => (
                    <div key={idx}>
                      <p className="fw-bold text-center mb-2 luan-giai-heading">
                        {item?.title}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.content_take_fee,
                        }}
                      ></div>
                    </div>
                  ));
                } else {
                  return (
                    <div className="vip-notice-content">
                      <Link
                        to={"/confirm-payment"}
                        className="text-danger text-center mt-2"
                      >
                        Bạn cần nâng cấp tài khoản VIP để xem được luận giải này
                        !
                      </Link>
                    </div>
                  );
                }
              case 9:
                if (ketqua?.is_unlocked === 1) {
                  return mapArr?.map((item, idx) => {
                    if (
                      Number(
                        JSON.parse(localStorage.getItem("ket-qua-params"))
                          ?.thangxem
                      ) +
                        idx ===
                        item?.month &&
                      idx < 3 &&
                      item?.content_take_fee !== null
                    ) {
                      return (
                        <div key={idx} className="mb-3">
                          <p className="fw-bold text-center mb-2 luan-giai-heading">
                            Tháng {item?.month}
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.content_take_fee,
                            }}
                          ></div>
                        </div>
                      );
                    } else {
                      if (idx < 3) {
                        return (
                          <div key={idx} className="mb-3">
                            <p className="fw-bold text-center mb-2 luan-giai-heading">
                              Tháng{" "}
                              {JSON.parse(
                                localStorage.getItem("ket-qua-params")
                              )?.thangxem + idx}
                            </p>
                            <div className="text-center">
                              Trong tháng này bạn không có vận hạn nào phải lưu
                              ý, vì thế hãy tập trung vào những việc bạn đang
                              làm, tận hưởng cuộc sống, suy nghĩ tích cực và
                              hướng về những điều tốt đẹp. Hãy xem lại luận giải
                              vận hạn 2024 để nhắc nhở bản thân về những cơ hội
                              và thách thức có thể đến với bạn bất cứ lúc nào,
                              đặc biệt là trong thời gian này nhé.
                            </div>
                          </div>
                        );
                      }
                    }
                  });
                } else {
                  return (
                    <div className="vip-notice-content">
                      <Link
                        to={"/confirm-payment"}
                        className="text-danger text-center mt-2"
                      >
                        Bạn cần nâng cấp tài khoản VIP để xem được luận giải này
                        !
                      </Link>
                    </div>
                  );
                }
              default:
                if (mapArr.length > 0) {
                  return mapArr?.map((item, idx) =>
                    ketqua?.is_unlocked === 1 ? (
                      <div key={idx}>
                        <p className="fw-bold text-center mb-2 luan-giai-heading">
                          {item?.title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.content_take_fee !== null
                                ? item?.content_take_fee
                                : item?.content,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div key={idx}>
                        <p className="fw-bold text-center mb-2 luan-giai-heading">
                          {item?.title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                        {handleSuggest(type)}
                      </div>
                    )
                  );
                } else {
                  return handleSuggest(type);
                }
            }
          })()}
        </div>
      </div>
    );
  };

  const handleSuggest = (type) => {
    switch (type) {
      case 1:
        if (ketqua?.tuoiam < 10) {
          return (
            <p className="d-flex fw-bold mt-2 mb-0 justify-content-center">
              Trên 9 tuổi mới ứng nghiệm
            </p>
          );
        }
      case 0:
      case 5:
        return (
          <>
            <p className="fw-bold mt-2 mb-0">Lời khuyên:</p>
            <Link to={"/confirm-payment"} className="text-danger mt-2">
              Hãy nâng cấp tài khoản VIP để xem được lời khuyên cho bản mệnh !
            </Link>
          </>
        );
      default:
        return (
          <Link
            to={"/confirm-payment"}
            className="text-danger text-center mt-2"
          >
            Bạn cần nâng cấp tài khoản VIP để xem được luận giải này !
          </Link>
        );
    }
  };

  const handleTitle = (type) => {
    // return smaller;
    switch (type) {
      case 0:
        return hoa_giap;
      case 1:
        return cuu_dieu_tinh_quan;
      case 3:
        return cung_menh;
      case 4:
        return cung_than;
      case 5:
        return lai_nhan_cung;
      case 6:
        return gio_sinh;
      case 7:
        return van_han_2023;
      case 8:
        return van_han_2024;
      case 9:
        return van_han_thang;

      default:
        break;
    }
  };

  const handleUnlock = async () => {
    setLoadingUnlock(true);
    if (info !== null) {
      try {
        const res = await sendPost(
          `/user/horoscope-log/unlock?horoslogId=${ketqua?.horoslogId}`
        );
        if (res.status === 200) {
          setKetqua(res.data?.data?.original?.data);
          setInfo({ ...info, amount_unlock: info?.amount_unlock - 1 });
          setTextMessage(res.data?.message);
          setLoadingUnlock(false);
        }
      } catch (error) {
        if (error.response?.status === 500) {
          window.location.assign(window.location.origin + `/login?horos`);
        } else {
          handleOpen();
          setLoadingUnlock(false);
        }
      }
    } else {
      handleOpenPNL();
      setLoadingUnlock(false);
    }
  };

  const handlePaymentWithoutLogin = async () => {
    try {
      const input = {
        code_log: ketqua?.horoslogCode,
        guest_code: localStorage.getItem("guest_code"),
      };
      const res = await sendGet(`/guest/unlock`, input);
      if (res.status === 200) {
        window.location.assign(res.data.data);
      }
    } catch (error) {}
  };
  const handlePaymentWithoutLogin2 = async () => {
    try {
      const input = {
        code: ketqua?.horoslogCode,
        guest_code: localStorage.getItem("guest_code"),
      };
      setOpenPNL(false);
      setAutoBankModal(true);
      setAmount(10000);
      setCode(ketqua?.horoslogCode);

      const intervalDuration = 100000; //cho call 50 lần;
      let elapsedTime = 0;

      const intervalId = setInterval(async () => {
        try {
          const response = await sendGet(`/user/check-order`, input);
          console.log(response);
          if (
            response?.data?.data?.status === 1 ||
            response?.data?.data?.is_unlocked === 1
          ) {
            setAutoBankModal(false);
            clearInterval(intervalId); // Dừng setInterval khi điều kiện đạt được
            alert("Chuyển tiền thành công !");
            window.location.assign(
              `/laso?code=${
                ketqua?.horoslogCode
              }&guest_code=${localStorage.getItem("guest_code")}`
            );
          }
          elapsedTime += 2000; // Thêm 2000ms vào thời gian đã trôi qua
          console.log(elapsedTime, intervalDuration);

          if (elapsedTime >= intervalDuration) {
            setAutoBankModal(false);
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error(error);
        }
      }, 2000);
      setIntervalId(intervalId);
      // window.location.assign(res.data.data);
    } catch (error) {
      console.log(error);
      // window.location.assign(
      //   window.location.origin + `/login?serviceId=${selectedItem}`
      // );
    }
  };

  const handleSubmitPDF = async () => {
    setIsDownload(true);
    const barChartInstance = window.Apex._chartInstances.find(
      (chart) => chart.id === "bar-chart"
    );
    const base64barChart = await barChartInstance.chart.dataURI();

    // console.log(base64barChart)

    const pieChartInstance = window.Apex._chartInstances.find(
      (chart) => chart.id === "pie-chart"
    );
    const base64pieChart = await pieChartInstance.chart.dataURI();
    const formData = new FormData();
    // formData.append("horoslog_id", ketqua?.horoslogId);
    // formData.append("guest_code", localStorage?.getItem("guest_code"));
    // formData.append("thang_xem", ketqua?.info?.thangxem);
    formData.append("bar_chart", base64barChart?.imgURI);
    formData.append("pie_chart", base64pieChart?.imgURI);
    try {
      const res = await sendPost(`chart-image`, formData);
      if (res.status === 200) {
        console.log(res, "success");
        handleDownloadPDF(res?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
      setIsDownload(false);
    }

    // // console.log("piechart", base64pieChart?.imgURI);
    // // console.log("barchart", base64barChart?.imgURI);
    // try {

    //   // const input = {
    //   //   horoslog_id: ketqua?.horoslogId,
    //   //   guest_code: localStorage?.getItem("guest_code"),
    //   //   thang_xem: ketqua?.info?.thangxem,
    //   //   bar_chart: base64barChart?.imgURI,
    //   //   pie_chart: base64pieChart?.imgURI,
    //   // };
    //   console.log(base64pieChart?.imgURI, "pie");

    //   // const res = await sendGet(`/pdf`);
    //   // if (res.status === 200) {
    //   //   // console.log(res, "success");
    //   //   var blob = new Blob([res.data], {
    //   //     type: "application/pdf",
    //   //   });
    //   //   // saveAs(blob, "test.pdf");
    //   //   FileSaver.saveAs(blob);
    //   // }
    // } catch (error) {
    //   console.log(error, "error");
    //   setTextMessage("Có lỗi xảy ra khi tải PDF");
    // }
    // console.log("chartInstance ", chartInstance);
    // console.log("base 64 bar chart", base64.imgURI);
    // const downloadLink = document.createElement("a");
    // downloadLink.href = base64.imgURI;
    // downloadLink.download = "image.png";
    // console.log("base 64", base64);
  };

  const handleUseCoupon = async () => {
    setIsDownload(true);
    try {
      const input = {
        coupon_code: document.getElementById("coupon").value,
        horoslog_id: ketqua?.horoslogId,
      };
      console.log(input);
      const res = await sendPost(`/user/coupon`, input);
      if (res) {
        console.log(res);
        setIsDownload(false);
        setCouponModal(false);
        window.location.assign(
          `/laso?code=${ketqua?.horoslogCode}&guest_code=${localStorage.getItem(
            "guest_code"
          )}`
        );
      }
    } catch (error) {
      console.log(error);
      setIsDownload(false);
      setCouponModal(false);
      setTextMessage(error?.response?.data?.error_msg);
    }
  };
  const handleDownloadPDF = async (data) => {
    // console.log(data, "data");
    // const link = document.createElement("a");
    // link.download = "Example-PDF-File";
    setLinkPdf(
      `${process.env.REACT_APP_API_URL}/pdf?horoslog_id=${
        ketqua?.horoslogId
      }&guest_code=${localStorage?.getItem("guest_code")}&thang_xem=${
        ketqua?.info?.thangxem
      }&user_id=${info?.id}&bar_chart=${data?.bar_chart}&pie_chart=${
        data?.pie_chart
      }`
    );
    // link.click();
    setIsDownload(false);
  };

  useEffect(() => {
    setTimeout(() => handleSubmitPDF(), 1000);
  }, [circleChartSeries, chartSeries]);

  return (
    <div style={isWide ? {} : { marginTop: "5vh" }}>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Thông báo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bạn không có đủ số lượt mở khoá luận giải? Bạn có muốn mua thêm lượt
          không?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => navigate("/confirm-payment")}
            appearance="primary"
          >
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={openPNL} onClose={handleClosePNL}>
        <Modal.Header>
          <Modal.Title>Thông báo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bạn chưa đăng nhập? Bạn có muốn thanh toán ngay không?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => navigate("/login?horos")} appearance="default">
            Đăng nhập
          </Button>
          <Button
            onClick={handlePaymentWithoutLogin2}
            appearance="primary"
            color="yellow"
          >
            Thanh toán ngay
          </Button>
          <Button
            onClick={() => {
              setOpenPNL(false);
              setCouponModal(true);
            }}
            appearance="primary"
            color="red"
          >
            Coupon
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Coupon */}
      <Modal open={couponModal} onClose={() => setCouponModal(false)}>
        <Modal.Header>
          <Modal.Title>Nhập Coupon để mở khoá luận giải chi tiết</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input id="coupon" placeholder="enter your coupon" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setCouponModal(false)} appearance="default">
            Huỷ
          </Button>
          <Button onClick={handleUseCoupon} appearance="primary" color="yellow">
            {isDownload ? <Loader /> : "Áp dụng"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Auto Bank */}
      <ModalMui
        open={autoBankModal}
        onClose={() => {
          clearInterval(intervalId);
          setAutoBankModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Autobank amount={amount} code={code} />
      </ModalMui>
      <div className="detail-heading"></div>
      <div className="detail-user-information">
        <div>
          <p className="detail-user-name">{ketqua?.info?.hoten}</p>
          <p className="detail-user-name">
            {Number(ketqua?.info?.ngaysinh) < 10
              ? `0${ketqua?.info?.ngaysinh}`
              : ketqua?.info?.ngaysinh}
            /
            {Number(ketqua?.info?.thangsinh) < 10
              ? `0${ketqua?.info?.thangsinh}`
              : ketqua?.info?.thangsinh}
            /{ketqua?.info?.namsinh} - Giờ{" "}
            {ketqua?.giosinh[ketqua?.info?.giosinh]}
          </p>
          <p className="detail-user-name">Mệnh: {ketqua?.menh}</p>
        </div>
      </div>
      {isWide ? (
        <></>
      ) : (
        <div className="zodiac-placeholder-detail">
          <img
            src={zodiac_animals_wheel}
            alt=""
            className="zodiac-animals-wheel-detail"
          />
          <img src={congiap} alt="" className="mobile-zodiac-animals-detail" />
        </div>
      )}
      <div
        className={`preview-note ${
          ketqua?.is_unlocked === 1 ? "unlocked" : ""
        }`}
      >
        <span className="mb-3 text-danger">
          Bạn đang sử dụng lượt tra cứu Tử Vi miễn phí chỉ xem được giới hạn các
          luận giải. Để xem được toàn bộ luận giải và giải pháp mà các chuyên
          gia tại xemtuvi.vn nghiên cứu và phân tích cho ngày tháng năm giờ sinh
          của bạn, vui lòng nâng cấp tài khoản VIP{" "}
        </span>
        <p>
          {info !== null ? (
            <p className="text-warning mt-3">
              Bạn còn {info?.amount_unlock} lượt mở khoá.
            </p>
          ) : (
            <></>
          )}
        </p>
        <div className="d-flex justify-content-center mt-3">
          <div className="button-mo-khoa" onClick={() => handleUnlock()}>
            {loadingUnlock ? (
              <Loader />
            ) : (
              <span>OK, Nâng cấp tài khoản VIP</span>
            )}
          </div>
        </div>
      </div>
      {handleLuangiai(0)}
      {handleLuangiai(1)}
      <div className="detail-laso">
        <img
          className="luan-giai-title-image"
          loading="lazy"
          alt=""
          src={thong_tin_ban_menh}
        />
        <Laso ketqua={ketqua} />
      </div>
      <div className="luan-giai-body">
        <img
          src={tinh_cach}
          className="luan-giai-title-image"
          loading="lazy"
          alt=""
        />
        <div
          className="luan-giai-introduce"
          dangerouslySetInnerHTML={{
            __html: introduceTopic?.find((item) => item?.type === 100)?.content,
          }}
        ></div>
        <div className="luan-giai-content">
          <div className="chart" id="bar-chart">
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={350}
              width="100%"
            />
          </div>
          {ketqua?.is_unlocked === 1 ? (
            <></>
          ) : (
            <div className="text-chart-not-unlock">
              <Link to={"/confirm-payment"} className="text-danger">
                Bạn cần mở khoá luận giải để xem đầy đủ mục này
              </Link>
            </div>
          )}
        </div>
      </div>
      {handleLuangiai(4)}
      {handleLuangiai(6)}
      {handleLuangiai(5)}
      {handleLuangiai(3)}
      {handleLuangiai(7)}
      {handleLuangiai(8)}
      {handleLuangiai(9)}
      <div className="luan-giai-body">
        <img
          className="luan-giai-title-image"
          loading="lazy"
          alt=""
          src={bat_tu_cai_van}
        />
        <div
          className="luan-giai-introduce"
          dangerouslySetInnerHTML={{
            __html: introduceTopic?.find((item) => item?.type === 14)?.content,
          }}
        ></div>
        <div className="luan-giai-content">
          <div className="circle-chart" id="pie-chart">
            <ReactApexChart
              options={circleChartOptions}
              series={circleChartSeries}
              type="pie"
              height={300}
              width="100%"
            />
          </div>
          {ketqua?.is_unlocked === 1 ? (
            <div>
              {/* <p className="fw-bold fs-5 mb-3">
                {`Vượng ` + ketqua?.dataCircle[0].name}
              </p>
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: ketqua?.dataCircle[0]?.much,
                }}
              ></div>
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: ketqua?.dataCircle[0]?.down,
                }}
              ></div> */}
              <p className="fw-bold fs-5 mb-3">
                {`Khuyết ` + ketqua?.dataCircle[1].name}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: ketqua?.dataCircle[1]?.little,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: ketqua?.dataCircle[1]?.up,
                }}
              ></div>
            </div>
          ) : (
            <div className="text-center text-danger">
              <Link
                to={"/confirm-payment"}
                className="text-danger text-center mt-2"
              >
                Bạn cần nâng cấp tài khoản VIP để xem được luận giải này !
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* <div className="laso">
        <div className="laso-logo"></div>
      </div> */}
      <div className="text-center mb-5">
        {/* <a
          href={`${process.env.REACT_APP_API_URL}/pdf`}
          download="Example-PDF-document"
          // target="_blank"
          rel="noreferrer"
        > */}
        {/* <Button onClick={handlePDF}>Tải PDF</Button> */}
        <div style={{ width: "500px", margin: "auto" }}>
          <iframe
            src={linkPdf}
            height="700"
            className="facebook-iframe"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
        {/* {ketqua.is_unlocked ?
          <Button onClick={handleSubmitPDF}>
            {isDownload ? <Loader /> : "Tải PDF"}
          </Button>
        : <Button>Mở khóa luận giải để xem chi tiết</Button>} */}
        <div
          className={`preview-pdf ${
            ketqua?.is_unlocked === 1 ? "unlocked" : ""
          }`}
        >
          <div className="d-flex justify-content-center mt-3">
            <div className="button-mo-khoa" onClick={() => handleUnlock()}>
              {loadingUnlock ? (
                <Loader />
              ) : (
                <span>OK, Nâng cấp tài khoản VIP</span>
              )}
            </div>
          </div>
        </div>
        {/* <button>Download .pdf file</button> */}
        {/* </a> */}
      </div>

      {/* <div className="detail-preview-heading">
        <Link to={"/preview-pdf"}>
          <Button className="">View PDF</Button>
        </Link>
      </div> */}

      {/* <div className="detail-pdf">
        <LuanGiaiPDF />
      </div> */}
    </div>
  );
};

export default Detail;

export const statusPickerData = [
  {
    label: "Hoạt động",
    value: 0,
  },
  {
    label: "Tạm ngừng",
    value: 1,
  },
];

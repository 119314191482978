import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sendGet, sendPost } from "../../../axios/axiosClient";
import { Button, Loader } from "rsuite";
import "./payment.css";
import {
  IoIosArrowBack,
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
} from "react-icons/io";
import Heading from "../../../layout/heading-layout/Heading";
import cat_image from "../../../assets/images/payment/meo_than_tai.png";
import shopping_card_image from "../../../assets/images/payment/image_shopping.png";
import { PiMedal } from "react-icons/pi";
import { useMedia } from "react-use";
import logo_mobile from "../../../assets/images/logo/logo-tuvihangngay-đen.png";
import { Modal } from "@mui/material";
import Autobank from "../auto-bank/AutoBank";

const Payment = () => {
  const location = useLocation();
  const [service, setService] = useState();
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isWide = useMedia("(min-width: 600px)");
  const [autoBankModal, setAutoBankModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState("");
  const [intervalId, setIntervalId] = useState(0);
  // const params = Object.fromEntries(
  //   new URLSearchParams(location.search.substring(1))
  // );

  const getService = async () => {
    try {
      const res = await sendGet(`/user/services`);
      if (res.status === 200) {
        setService(res.data?.data);
        setSelectedItem(res.data?.data[0]?.id);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getService();
  }, []);

  // console.log(params);

  const handleOrder = async () => {
    try {
      const input = {
        serviceId: selectedItem,
      };
      const res = await sendPost(`/user/orders`, input);
      if (res.status === 200) {
        window.location.assign(res.data.data);
      }
    } catch (error) {
      // console.log(error);
      window.location.assign(
        window.location.origin + `/login?serviceId=${selectedItem}`
      );
    }
  };
  const handleOrder2 = async () => {
    try {
      const res = await sendPost(`/user/orders`, { serviceId: selectedItem });
      console.log(res);

      if (res.status === 200) {
        setAutoBankModal(true);
        setAmount(res?.data?.data?.cost);
        setCode(res?.data?.data?.code);

        const intervalDuration = 100000 //cho call 50 lần;
        let elapsedTime = 0;
        
        const intervalId = setInterval(async () => {
          try {
            const response = await sendGet(`/user/check-order`, {code : res?.data?.data?.code});
            console.log(response);
            if (response?.data?.data?.status === 1 || response?.data?.data?.is_unlocked === 1) {
              setAutoBankModal(false);
              clearInterval(intervalId); // Dừng setInterval khi điều kiện đạt được
              alert("Chuyển tiền thành công ! Vào lá số bạn muốn xem và MỞ KHOÁ");
              window.location.assign(window.location.origin + "/");
            }
            elapsedTime += 2000; // Thêm 2000ms vào thời gian đã trôi qua
            
            if (elapsedTime >= intervalDuration) {
              setAutoBankModal(false);
              clearInterval(intervalId);
            }
          } catch (error) {
            console.error(error);
          }
          
        }, 2000);
        setIntervalId(intervalId);

        // window.location.assign(res.data.data);
      }
    } catch (error) {
      // console.log(error);
      window.location.assign(
        window.location.origin + `/login?serviceId=${selectedItem}`
      );
    }
  };

  return (
    <div className="payment">
      {isWide ? (
        <Heading />
      ) : (
        <div className="pt-4 d-flex justify-content-center">
          <Link to={"/"}>
            <img src={logo_mobile} alt="logo" className="payment-mobile-logo" />
          </Link>
        </div>
      )}

      <div className="paymentBody">
        <div className="d-flex align-items-center justify-content-center text-dark mb-3">
          <img src={cat_image} alt="cat_image" className="vip-heading-image" />
          <span className="payment-heading-text">CHỌN MUA GÓI VIP</span>
          <img src={cat_image} alt="cat_image" className="vip-heading-image" />
        </div>
        <div className="payment-card p-2 w-100">
          {/* <div className="d-flex mb-3 justify-content-between align-items-center">
            <Link to={"/ket-qua"} className="text-start">
              <IoIosArrowBack />
            </Link>
            <h4 className="fw-bold text-center">{service?.name}</h4>
            <span></span>
          </div> */}
          {isLoading ? (
            <div
              className="d-flex justify-content-center w-100 align-items-center"
              style={{ minHeight: "10vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {service?.map((item, idx) => (
                <div
                  className={`payment-item ${
                    selectedItem === idx ? "selected-payment-item" : ""
                  }`}
                  key={idx}
                  onClick={() => setSelectedItem(item?.id)}
                >
                  <div>
                    {selectedItem === item?.id ? (
                      <IoIosRadioButtonOn />
                    ) : (
                      <IoIosRadioButtonOff />
                    )}
                  </div>
                  <div className="payment-main-select-item">
                    <p className="text-dark fw-bold">{item?.name}</p>
                    {item?.description?.length ? (
                      <p style={{ color: "#008ffb" }}>
                        <PiMedal /> {item?.description}
                      </p>
                    ) : (
                      <p></p>
                    )}
                    <p>
                      <span style={{ textDecoration: "line-through" }}>
                        {(item?.cost).toLocaleString("vi", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </span>
                      <span className="ms-2">-{item?.discount}%</span>
                    </p>
                    <p>
                      <span>Giá:</span>
                      <span className="ms-2 text-danger fw-bold">
                        {(item?.total).toLocaleString("vi", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="my-4 d-flex justify-content-center">
          <Button
            onClick={handleOrder2}
            className="payment-button"
            appearance="primary"
            color="green"
          >
            <img
              src={shopping_card_image}
              alt=""
              className="shopping-card-image-button"
            />{" "}
            Đặt mua ngay
          </Button>
        </div>
        <div className="payment-notice">
          <p>
            1. Sau khi đăng ký VIP bạn tự xem luận giải Tử Vi chi tiết và tải
            file PDF nội dung thông tin dài 30-40 trang lưu vĩnh viễn.
          </p>
          <p>
            2. Sau khi đăng ký VIP bạn được tham gia buổi hỏi đáp online trực
            tiếp với Thiện An Tín Tâm trên zoom.
          </p>
          <p>
            3. Lượt online xem luận giải Tử Vi được tặng bạn xem trên web trong
            1 lần tra cứu và không tải được file PDF luận giải chi tiết cùng
            hướng dẫn.
          </p>
        </div>
        <div className="thong-tin-chuyen-khoan">
          <p className="fw-bold"> A. THÔNG TIN CHUYỂN KHOẢN THANH TOÁN:</p>
        </div>
        <div className="noi-dung-chuyen-khoan">
          <p className="fw-bold"> B. NỘI DUNG CHUYỂN KHOẢN:</p>
        </div>
      </div>
      {/* Modal Auto Bank */}
      <Modal
        open={autoBankModal}
        onClose={() => {
          clearInterval(intervalId);
          setAutoBankModal(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Autobank 
          amount={amount}
          code={code}
        />
      </Modal>
    </div>
  );
};

export default Payment;

import React, { useContext, useState } from "react";
import { useMedia } from "react-use";
import route from "../../route";
import { Route, Routes, useLocation } from "react-router-dom";
import UserHeading from "../../user/UserHeading";
import UserAside from "../../user/UserAside";
import Aside from "../aside/Aside";
import { Home } from "../../../components/user/home/Home";
import { InfoContextProvider } from "../../../context/InfoContext";

const Wrapper = () => {
  const isWide = useMedia("(min-width: 600px)");
  const [isShowAside, setIsShowAside] = useState(false);
  const { info, setInfo } = useContext(InfoContextProvider);
  const location = useLocation();

  const handleAdminPath = () => {
    const find = route.find(
      (i) => i.path === location?.pathname && i.type === "admin"
    );
    if (find) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Aside />
      <div
        className={info?.role === 1 && handleAdminPath() ? "admin-wrapper" : ""}
      >
        <Routes>
          {route.map((item, key) => (
            <Route key={key} path={item?.path} element={item?.element} />
          ))}
          <Route path={"/*"} element={<Home />} />
        </Routes>
      </div>
    </>
  );
};

export default Wrapper;

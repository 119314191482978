import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import route from "./layout/route";
import Landing from "./layout/Landing";
import Admin from "./layout/Admin";
import PageNotFound from "./layout/page-not-found/PageNotFound";
import User from "./layout/User";
import "react-quill/dist/quill.snow.css";
import { Suspense } from "react";
import { Loader } from "rsuite";
import Wrapper from "./layout/_layout/wrapper/Wrapper";
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Wrapper />
      </Suspense>

      {/* <Routes>
        <Route path="*" element={<PageNotFound />} />
      </Routes> */}
    </>
  );
}

const Loading = () => {
  return (
    <div>
      <Loader />
    </div>
  );
};

export default App;

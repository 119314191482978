import React, { useEffect, useState } from "react";
import { sendGet } from "../../../axios/axiosClient";
import "./posts.css";
import { Pagination, Placeholder } from "rsuite";
import no_content from "../../../assets/images/content-error.webp";

const Posts = () => {
  const [listPosts, setListPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const getListPosts = async () => {
    try {
      const res = await sendGet(`/user/posts`);
      if (res.status === 200) {
        setListPosts(res.data.data.data);
        setTotalItems(res.data.data.total);
      }
    } catch (error) {}
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getListPosts();
  }, []);

  return (
    <div className="posts-content">
      {isLoading ? (
        <>
          {Array.from({ length: 4 }, (_, i) => (
            <div key={i} className="card p-2 mb-3">
              <Placeholder.Paragraph graph="image" rows={4} active />
            </div>
          ))}
        </>
      ) : (
        <>
          {listPosts?.map((item, idx) => (
            <div className="card p-2 mb-3" key={idx}>
              <div className="post-preview-content">
                <div>
                  <img
                    src={item?.images[0]}
                    className="post-preview-image"
                    alt="post-preview-image"
                  />
                </div>
                <div>
                  <p className="fw-bold post-title">{item?.title}</p>
                  <div
                    className="post-preview-description"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
          {totalItems > 0 ? (
            <Pagination
              layout={["limit", "-", "pager"]}
              size={"sm"}
              ellipsis
              next
              prev
              boundaryLinks
              total={totalItems}
              limit={limit}
              limitOptions={[10, 30, 50]}
              maxButtons={3}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={setLimit}
            />
          ) : (
            <div className="">
              <div className="d-flex justify-content-center">
                <img
                  src={no_content}
                  alt="no-content"
                  className="no-content-image"
                />
              </div>
              <p className="fw-bold text-center fs-3 mt-3">
                Xin lỗi, hiện tại không có bài viết nào để hiện thị.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Posts;

import React, { useEffect, useState } from "react";
import { sendGet } from "../../../axios/axiosClient";
import "./user.css";
import { Table } from "react-bootstrap";
import { Pagination, Placeholder } from "rsuite";
import { Link } from "react-router-dom";
import Heading from "../../../layout/heading-layout/Heading";
import Cookies from "js-cookie";

const User = () => {
  const [listOrder, setListOrder] = useState([]);
  const [listHoros, setListHoros] = useState([]);
  // horos
  const [totalHoros, setTotalHoros] = useState(0);
  const [pageHoros, setPageHoros] = useState(1);
  const [limitHoros, setLimitHoros] = useState(10);

  const [listAffiliate, setListAffiliate] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [affPage, setAffPage] = useState(1);
  const [affLimit, setAffLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHoros, setIsLoadingHoros] = useState(false);

  const getWithdraw = async () => {
    const res = await sendGet(`/withdraws`);
  };
  const getHistory = async () => {
    const res = await sendGet(`/lich-su-xem`);
  };

  const getOrder = async () => {
    setIsLoadingHoros(true);
    try {
      const res = await sendGet(`/user/orders`);
      if (res.status === 200) {
        setListOrder(res.data.data.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {
      if (error.response?.data?.code) {
        window.location.assign(window.location.origin + "/login");
        Cookies.remove("token");
      }
    }
    setTimeout(() => setIsLoadingHoros(false), 1000);
  };

  const getAffiliate = async () => {
    try {
      const res = await sendGet(`/user/history-affiliates`);
      if (res.status === 200) {
      }
    } catch (error) {
      if (error.response?.data?.code) {
        window.location.assign(window.location.origin + "/login");
        Cookies.remove("token");
      }
    }
  };

  const getHorosCode = async () => {
    setIsLoading(true);
    try {
      const input = {
        page: pageHoros,
        page_size: limitHoros,
      };
      const res = await sendGet(`/user/horoscope-log/history`, input);
      if (res.status === 200) {
        setTotalHoros(res.data?.data?.total);
        setListHoros(res.data?.data?.data);
      }
    } catch (error) {
      if (error.response?.data?.code) {
        window.location.assign(window.location.origin + "/login");
        Cookies.remove("token");
      }
    }
    setTimeout(() => setIsLoading(false), 1000);
  };

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getOrder();
  }, [limit, page]);
  useEffect(() => {
    setPageHoros(1);
  }, [limitHoros]);

  useEffect(() => {
    getHorosCode();
  }, [limitHoros, pageHoros]);

  useEffect(() => {
    getAffiliate();
  }, []);

  const handleStatus = (status) => {
    switch (status) {
      case 0:
        return "Đang chờ";
      case 1:
        return "Thành công";
      case 2:
        return "Lỗi - ";
      default:
        break;
    }
  };

  return (
    <div className="user-body">
      <Heading />
      <div className="mobile-mt"></div>
      <div className="user-history">
        <div className="mb-5">
          <h4 className="fw-bold text-dark">Lịch sử mua Lượt</h4>
          <Table bordered responsive className="mt-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Dịch Vụ</th>
                <th>Mã giao dịch</th>
                {/* <th>CODE Thanh Toán</th> */}
                <th>Phương Thức Thanh Toán</th>
                <th>Giá</th>
                <th>Trạng Thái</th>
              </tr>
            </thead>
            <tbody>
              {listOrder?.length > 0 ? (
                <>
                  {listOrder?.map((item, idx) => (
                    <tr key={idx}>
                      <td style={{ width: "100px" }}>{idx + 1}</td>
                      <td>{item?.service}</td>
                      <td>{item?.code}</td>
                      {/* <td>{item?.code_to_pay}</td> */}
                      <td>{item?.payment_method}</td>
                      <td>{item?.cost}</td>
                      <td>
                        {handleStatus(item?.status)}{" "}
                        {item?.status === 2 ? (
                          <a
                            href="https://sandbox.vnpayment.vn/apis/docs/bang-ma-loi/"
                            target="_blank"
                          >
                            {item?.note}
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    {isLoading ? (
                      <td colSpan={7}>
                        <Placeholder.Graph
                          rows={1}
                          width={"100%"}
                          height={10}
                          active
                        />
                      </td>
                    ) : (
                      <td colSpan={7} className="text-center">
                        Không có dữ liệu
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {totalItems > 0 ? (
            <Pagination
              layout={["limit", "-", "pager"]}
              size={"sm"}
              ellipsis
              next
              prev
              boundaryLinks
              total={totalItems}
              limit={limit}
              limitOptions={[10, 30, 50]}
              maxButtons={3}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={setLimit}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <h4 className="fw-bold text-dark">Lịch sử mua lá số</h4>
          <Table bordered responsive className="mt-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Tên</th>
                <th>Lá số</th>
                {/* <th>CODE Thanh Toán</th> */}
                <th>Trạng Thái</th>
              </tr>
            </thead>
            <tbody>
              {listHoros?.length > 0 ? (
                <>
                  {listHoros?.map((item, idx) => (
                    <tr key={idx}>
                      <td style={{ width: "100px" }}>
                        {(pageHoros - 1) * limitHoros + idx + 1}
                      </td>
                      <td>{item?.name}</td>
                      <td>
                        <Link to={`/laso?code=${item?.code}`}>
                          {item?.code}
                        </Link>
                      </td>
                      <td>
                        ---
                        {/* {handleStatus(item?.status)}{" "}
                        {item?.status === 2 ? (
                          <a
                            href="https://sandbox.vnpayment.vn/apis/docs/bang-ma-loi/"
                            target="_blank"
                          >
                            {item?.note}
                          </a>
                        ) : (
                          ""
                        )} */}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    {isLoadingHoros ? (
                      <td colSpan={4}>
                        <Placeholder.Graph
                          rows={1}
                          width={"100%"}
                          height={10}
                          active
                        />
                      </td>
                    ) : (
                      <td colSpan={4} className="text-center">
                        Không có dữ liệu
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {totalHoros > 0 ? (
            <Pagination
              layout={["limit", "-", "pager"]}
              size={"sm"}
              ellipsis
              next
              prev
              boundaryLinks
              total={totalHoros}
              limit={limitHoros}
              limitOptions={[10, 30, 50]}
              maxButtons={3}
              activePage={pageHoros}
              onChangePage={setPageHoros}
              onChangeLimit={setLimitHoros}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="">
          <h4 className="fw-bold text-dark">Lịch sử Affiliate</h4>
        </div>
        <Table bordered responsive className="mt-3">
          <thead>
            <tr>
              <th>#</th>
              <th>#</th>
              <th>#</th>
              <th>#</th>
              <th>#</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6} className="text-center">
                Không có dữ liệu
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default User;

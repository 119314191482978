import React, { useContext, useEffect, useState } from "react";
import { sendGet } from "../../../axios/axiosClient";
import { useLocation, useNavigate } from "react-router-dom";
import { KetquaContextPro } from "../../../context/KetquaContext";
import { Message, useToaster } from "rsuite";
import moment from "moment";

const Laso = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const orderCode = location.search.substring(1);
  const { ketqua, setKetqua } = useContext(KetquaContextPro);
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const getLaso = async () => {
    try {
      const input = {
        code: params.get('code'),
        guest_code: params.get('guest_code'),
      };
      const res = await sendGet(`/laso`, input);
      if (res.status === 200) {
        setKetqua(res.data?.data);
        navigate("/ket-qua");
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
      navigate("/");
    }
  };

  useEffect(() => {
    getLaso();
  }, []);

  return <></>;
};

export default Laso;

import React, { useContext } from "react";
import "./heading.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo-tuvihangngay-gold.png";
import logo_blank from "../../assets/images/logo/logo-tuvihangngay-đen.png";
import { InfoContextProvider } from "../../context/InfoContext";
import Cookies from "js-cookie";
import heading_buy_vip_image from "../../../src/assets/images/heading/mua-vip.png";
import { useMedia } from "react-use";
import mobile_logo from "../../assets/images/logo/logo-black.png";

const renderButton = (props, ref) => {
  return (
    <span className="link-animation" {...props} ref={ref}>
      TÀI KHOẢN
    </span>
  );
};

const Heading = () => {
  const { info, setInfo } = useContext(InfoContextProvider);
  const location = useLocation();
  const isWide = useMedia("(min-width: 600px)");

  return isWide ? (
    <div className="ket-qua-header">
      <div>
        <Link to={"/"} className="ketqua-logo">
          <img
            src={location.pathname === "/confirm-payment" ? logo_blank : logo}
            alt=""
            className=""
          />
        </Link>
      </div>
      <div className="ket-qua-header-menu">
        <div className="ket-qua-header-menu-background"></div>
        <Link to={"/ket-qua"} className="header-animation">
          Luận giải
        </Link>
        <Link to={"/confirm-payment"}>
          {/* <FaShoppingBag className="me-2 mb-2" /> */}
          <div className="d-flex align-items-center" style={{ gap: ".5vw" }}>
            <img src={heading_buy_vip_image} alt="" className="mua-vip-image" />{" "}
            <span>Mua VIP</span>
          </div>
        </Link>
        {Cookies.get("token") ? (
          <Link className="header-animation" to={"/user"}>
            Thông tin người dùng
          </Link>
        ) : (
          <Link className="header-animation" to={"/login"}>
            Đăng ký/Đăng nhập
          </Link>
        )}
      </div>
    </div>
  ) : (
    <div className="ket-qua-header-mobile">
      <Link to={"/"}>
        <img
          src={mobile_logo}
          alt="mobile-logo"
          className="mobile-heading-logo"
        />
      </Link>
      <Link to={"/ket-qua"} className="header-mobile-link">
        Luận giải
      </Link>
      <Link to={"/confirm-payment"}>
        {/* <FaShoppingBag className="me-2 mb-2" /> */}
        <div className="d-flex align-items-center gap-1">
          <img
            src={heading_buy_vip_image}
            alt=""
            className="mua-vip-image mb-2"
          />{" "}
          <span className="header-mobile-link" style={{ color: "#a8581b" }}>
            Mua VIP
          </span>
        </div>
      </Link>
      {Cookies.get("token") ? (
        <Link to={"/user"} className="header-mobile-link">
          Thông tin người dùng
        </Link>
      ) : (
        <Link to={"/login"} className="header-mobile-link">
          Đăng ký/Đăng nhập
        </Link>
      )}
    </div>
  );
};

export default Heading;

import React, { useState } from "react";
import { useMedia } from "react-use";
import "./laso.css";
import "../user/ket-qua/ketqua.css";
import logo_xemtuvi from "../../assets/images/logo/logo-tuvihangngay-đen.png";
import thien_an_logo from "../../assets/images/logo/thien-an-la-so-logo.png";
import background_laso from "../../assets/images/zodiac-animals/opacity-zodiac-wheel_35.png";
import { useLocation } from "react-router-dom";

const Laso = ({ ketqua }) => {
  const [cungHover, setCungHover] = useState("cung-0");
  const isWide = useMedia("(min-width: 783px)");
  const location = useLocation();

  const handleColor = (item) => {
    switch (item[1]) {
      case 0:
        // no color
        return <span>{item[0]}</span>;
      case 1:
        // tho
        return (
          <span className="fw-bold" style={{ color: "orange" }}>
            {item[0]}
          </span>
        );
      case 2:
        // hoa
        return (
          <span className="fw-bold" style={{ color: "red" }}>
            {item[0]}
          </span>
        );
      case 3:
        // thuy
        return (
          <span className="fw-bold" style={{ color: "#0f6af5" }}>
            {item[0]}
          </span>
        );
      case 4:
        // moc
        return (
          <span className="fw-bold" style={{ color: "#009130" }}>
            {item[0]}
          </span>
        );
      case 5:
        // kim
        return (
          <span className="fw-bold" style={{ color: "#999" }}>
            {item[0]}
          </span>
        );
      default:
        return "";
    }
  };

  const handleDiaban = (item, idx) => {
    // if (ketqua?.info?.ID_LUUDAIVAN === "1") {
    return (
      // check 10 nam
      <div
        className={`cung  ${isWide ? "p-2" : "p-1"}`}
        onMouseEnter={() => setCungHover(`cung-${idx}`)}
        // onMouseLeave={() => console.log(idx, "leave index")}
      >
        {/* {handleCungHover(idx)} */}
        <div className="cung-top d-flex">
          <div className="col-3 text-start">
            <p className="smallText">{handleColor(item[2])}</p>
            {/* <p className="smallText fw-bold">{handleColor(item[10])}</p> */}
          </div>
          <div className="col-6 text-center cung-top-centerText">
            <p className="fw-bold">{handleColor(item[0])}</p>
            <p className="fw-bold">{handleColor(item[14])}</p>
            <p className="fw-bold">{handleColor(item[15])}</p>
          </div>
          <div className="col-3 text-end">
            <p className="smallText">{handleColor(item[1])}</p>
            {/* <p className="smallText fw-bold">{handleColor(item[11])}</p> */}
          </div>
        </div>
        <div className="cung-body">
          <div className="cung-body-top d-flex flex-wrap">
            {item.map((x, key) => {
              if (
                key > 16 &&
                x[0] !== "Tuần" &&
                x[0] !== "Triệt" &&
                x[0] !== "Tuần-Triệt"
              ) {
                return (
                  <div
                    key={key}
                    className={`col-6 cung-body-top-text ${
                      key % 2 === 0 ? "ps-1" : "pe-1"
                    }`}
                  >
                    {handleColor(x)}
                  </div>
                );
              }
            })}
          </div>
          {/* <div className="cung-body-bottom">
            <p>{handleColor(item[4])}</p>
            <p>{handleColor(item[5])}</p>
            <p>{handleColor(item[6])}</p>
            <p>{handleColor(item[7])}</p>
          </div> */}
        </div>
        <div className="cung-bottom">
          <div className="d-flex">
            <div className="col text-start">{handleColor(item[8])}</div>
            <div className="col text-center">{handleColor(item[3])}</div>
            <div className="col text-end">{handleColor(item[9])}</div>
          </div>
        </div>
        {handleTrietTuan(item, idx)}
      </div>
    );
    // } else {
    //   // uncheck 10 nam
    //   return (
    //     <div
    //       className={`cung ${isWide ? "p-2" : "p-1"}`}
    //       onMouseEnter={() => setCungHover(`cung-${idx}`)}
    //     >
    //       <div className="cung-top d-flex">
    //         <div className="col-3 text-start">
    //           <p className="smallText">{handleColor(item[2])}</p>
    //         </div>
    //         <div className="col-6 text-center cung-top-centerText">
    //           <p className="fw-bold">{handleColor(item[0])}</p>
    //           <p className="fw-bold">{handleColor(item[8])}</p>
    //           <p className="fw-bold">{handleColor(item[9])}</p>
    //         </div>
    //         <div className="col-3 text-end">
    //           <p className="smallText">{handleColor(item[1])}</p>
    //         </div>
    //       </div>
    //       <div className="cung-body">
    //         <div className="cung-body-top d-flex flex-wrap">
    //           {item.map((x, key) => {
    //             if (
    //               key > 10 &&
    //               x[0] !== "Tuần" &&
    //               x[0] !== "Triệt" &&
    //               x[0] !== "Tuần-Triệt"
    //             ) {
    //               return (
    //                 <div
    //                   key={key}
    //                   className={`col-6 cung-body-top-text ${
    //                     key % 2 === 0 ? "ps-1" : "pe-1"
    //                   }`}
    //                 >
    //                   {handleColor(x)}
    //                 </div>
    //               );
    //             }
    //           })}
    //         </div>
    //         <div className="cung-body-bottom">
    //           <p>{handleColor(item[4])}</p>
    //           <p>{handleColor(item[5])}</p>
    //           <p>{handleColor(item[6])}</p>
    //           <p>{handleColor(item[7])}</p>
    //         </div>
    //       </div>
    //       <div className="cung-bottom">
    //         <div className="d-flex">
    //           <div className="col text-center">{handleColor(item[3])}</div>
    //         </div>
    //       </div>
    //       {handleTrietTuan(item, idx)}
    //     </div>
    //   );
    // }
  };

  const handleTrietTuan = (item, idx) => {
    if (item.some((x) => x[0] === "Tuần-Triệt")) {
      return (
        <div className="sao-tuan-triet" style={positionTrietTuan(idx)}>
          Tuần - Triệt
        </div>
      );
    } else if (item.some((x) => x[0] === "Tuần")) {
      return (
        <div className="sao-tuan-triet" style={positionTrietTuan(idx)}>
          Tuần
        </div>
      );
    } else if (item.some((x) => x[0] === "Triệt")) {
      return (
        <div className="sao-tuan-triet" style={positionTrietTuan(idx)}>
          Triệt
        </div>
      );
    }
  };

  const positionTrietTuan = (idx) => {
    switch (idx) {
      case 1:
        return {
          bottom: "-3%",
          right: "-27.5%",
          marginLeft: "auto",
          marginRight: "auto",
        };
      case 2:
        return {
          bottom: "-3%",
          left: "-27.5%",
          marginLeft: "auto",
          marginRight: "auto",
        };
      case 4:
      case 6:
      case 8:
        return {
          top: "-3%",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
        };
      case 9:
        return {
          top: "-3%",
          right: "-27.5%",
          marginLeft: "auto",
          marginRight: "auto",
        };
      case 10:
        return {
          top: "-3%",
          left: "-27.5%",
          marginLeft: "auto",
          marginRight: "auto",
        };
      default:
        return {
          bottom: "-3%",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
        };
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <table
        className="table-la-so"
        style={{
          width: isWide && location.pathname === "/detail" ? "50vw" : "",
        }}
      >
        <tbody>
          <tr>
            {ketqua?.diaban?.map((item, i) => {
              if (i < 4) {
                return <td key={i}>{handleDiaban(item, i)}</td>;
              }
            })}
          </tr>
          <tr>
            <td>{handleDiaban(ketqua?.diaban[4], 4)}</td>
            <td colSpan={2} rowSpan={2} className={`thienban`}>
              <img
                loading="lazy"
                src={background_laso}
                alt=""
                className="background-la-so"
              />
              <img
                loading="lazy"
                src={thien_an_logo}
                alt=""
                className="thien-an-logo"
              />
              {/* <div className={`background-thien-ban ${cungHover}`}></div> */}
              <div>
                <div className="thien-ban-top">
                  <img
                    loading="lazy"
                    src={logo_xemtuvi}
                    alt=""
                    className="logo-xemtuvi"
                  />
                  {/* <p className="fw-bold thienban-heading">
                    Xem tử vi - tri vận mệnh
                  </p> */}
                  <p className="font-but-long">Lá số tử vi</p>
                </div>
                <div className="thien-ban-body">
                  {ketqua?.thienban?.map((item, idx) => (
                    <div
                      key={idx}
                      className="thien-ban-row"
                      // style={{
                      //   width: isWide ? "70%" : "100%",
                      //   margin: "0 auto 5px auto",
                      // }}
                    >
                      {item?.map((x, i) => {
                        switch (i) {
                          case 0:
                            return (
                              <span key={i} className="thienban-title">
                                {handleColor(x)}
                              </span>
                            );
                          case 1:
                            return (
                              <span
                                key={i}
                                className="thienban-content"
                                style={{
                                  display: "inline-block",
                                  width: "fit-content",
                                  paddingRight: isWide ? "1vw" : "5px",
                                  // width: isWide ? "4.5vw" : "40px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {handleColor(x)}{" "}
                              </span>
                            );
                          default:
                            return (
                              <span key={i} className="thienban-content">
                                {handleColor(x)}{" "}
                              </span>
                            );
                        }
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </td>
            <td>{handleDiaban(ketqua?.diaban[5], 5)}</td>
          </tr>
          <tr>
            <td>{handleDiaban(ketqua?.diaban[6], 6)}</td>
            <td>{handleDiaban(ketqua?.diaban[7], 7)}</td>
          </tr>
          <tr>
            {ketqua?.diaban?.map((item, i) => {
              if (i > 7) {
                return <td key={i}>{handleDiaban(item, i)}</td>;
              }
            })}
          </tr>
          {/* bottom table (table addons description) */}
          <tr>
            <td colSpan={4}>
              <div className="d-flex justify-content-between px-2 bottomDefinition">
                <div className="d-flex gap-2">
                  <div>
                    <span className="fw-bold text-danger">M:</span>
                    <span className="fw-bold">Miếu</span>
                  </div>
                  <div>
                    <span className="fw-bold text-danger">V:</span>
                    <span className="fw-bold">Vượng</span>
                  </div>
                  <div>
                    <span className="fw-bold text-danger">Đ:</span>
                    <span className="fw-bold">Đắc</span>
                  </div>
                  <div>
                    <span className="fw-bold text-danger">B:</span>
                    <span className="fw-bold">Bình hoà</span>
                  </div>
                  <div>
                    <span className="fw-bold text-danger">H:</span>
                    <span className="fw-bold">Hãm</span>
                  </div>
                </div>
                <div className={`d-flex ${isWide ? "gap-4" : "gap-3"}`}>
                  <div className="thuoc-tinh">
                    <div
                      className="box"
                      style={{ backgroundColor: "#999" }}
                    ></div>
                    <div className="thuoc-tinh-text">Kim</div>
                  </div>
                  <div className="thuoc-tinh">
                    <div
                      className="box"
                      style={{ backgroundColor: "#009130" }}
                    ></div>
                    <div className="thuoc-tinh-text">Mộc</div>
                  </div>
                  <div className="thuoc-tinh">
                    <div
                      className="box"
                      style={{ backgroundColor: "#0f6af5" }}
                    ></div>
                    <div className="thuoc-tinh-text">Thuỷ</div>
                  </div>
                  <div className="thuoc-tinh">
                    <div
                      className="box"
                      style={{ backgroundColor: "red" }}
                    ></div>
                    <div className="thuoc-tinh-text">Hoả</div>
                  </div>
                  <div className="thuoc-tinh">
                    <div
                      className="box"
                      style={{ backgroundColor: "orange" }}
                    ></div>
                    <div className="thuoc-tinh-text">Thổ</div>
                  </div>
                </div>
                <div>
                  <small className="laso_id">
                    Lá số: #{ketqua?.horoslogId}
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Laso;

import React, { useEffect, useState } from "react";
import "./forgot-password.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Form,
  Loader,
  Message,
  useToaster,
} from "rsuite";
import { sendPost } from "../../../axios/axiosClient";

const ForgotPassword = () => {
  const [formValue, setFormValue] = useState({});
  const [updatePassword, setUpdatePassword] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const type = "warning";
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const checkHaveToken = location?.search?.substring(1).startsWith("token")
    ? true
    : false;
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const message = (
    <Message type={type} closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleSendEmail = async () => {
    setLoadingSendEmail(true);
    try {
      const input = {
        email: formValue?.email,
      };
      const res = await sendPost(`/forget-password`, input);
      if (res.status === 200) {
        setTextMessage(res?.data?.data);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.message);
    }
    setLoadingSendEmail(false);
  };

  const handleChangePassword = async () => {
    if (updatePassword.newPassword !== updatePassword.reEnterPassword) {
      setTextMessage("Mật khẩu và Mật khẩu nhập lại phải trùng nhau.");
      return;
    }
    try {
      const input = {
        token: location?.search?.substring(7),
        new_password: updatePassword.newPassword,
      };
      const res = await sendPost(`/check-token`, input);
      if (res.status === 200) {
        setTextMessage(res?.data?.data);
        setChangePasswordSuccess(true);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  useEffect(() => {
    // if (location?.search?.substring(1).startsWith("token")) {
    //   handleCheckToken();
    // }
  }, []);

  return (
    <div className="login">
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "10vh" }}
      >
        <div className="loginBody">
          <Link to={"/"}>
            <div className="page-logo"></div>
          </Link>
          {checkHaveToken ? (
            changePasswordSuccess ? (
              <>
                <h3 className="fw-bold text-center mt-5">
                  Đổi mật khẩu thành công
                </h3>
                <p className="text-center my-3">
                  Mật khẩu của bạn đã được đổi lại thành công, quay lại đăng
                  nhập.
                </p>
                <div className="d-flex justify-content-center">
                  <Button
                    appearance="default"
                    className="login-button"
                    onClick={() => navigate("/login")}
                  >
                    Quay lại đăng nhập
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h3 className="fw-bold text-center mt-5">Đặt lại mật khẩu</h3>
                <p className="text-center my-3">
                  Xác thực email thành công, tạo lại mật khẩu mới tại đây.
                </p>
                <Form
                  fluid
                  formValue={updatePassword}
                  onChange={setUpdatePassword}
                >
                  <Form.Group controlId="newPassword">
                    <Form.Control
                      name="newPassword"
                      type="password"
                      placeholder="Đặt mật khẩu mới"
                      style={{ borderRadius: "2rem" }}
                    />
                  </Form.Group>
                  <Form.Group controlId="reEnterPassword">
                    <Form.Control
                      name="reEnterPassword"
                      type="password"
                      placeholder="Nhập lại mật khẩu"
                      style={{ borderRadius: "2rem" }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <ButtonToolbar>
                      <Button
                        appearance="default"
                        color="red"
                        className="login-button"
                        style={{
                          backgroundColor: "red",
                          border: "none",
                          color: "white",
                        }}
                        onClick={handleChangePassword}
                      >
                        Đặt lại mật khẩu
                      </Button>
                      <Button
                        appearance="default"
                        className="login-button"
                        onClick={() => navigate("/login")}
                      >
                        Quay lại đăng nhập
                      </Button>
                    </ButtonToolbar>
                  </Form.Group>
                </Form>
              </>
            )
          ) : (
            <>
              <h3 className="fw-bold text-center mt-5">Tìm lại mật khẩu</h3>
              <p className="text-center my-3">
                Hãy nhập email để khôi phục mật khẩu cho tài khoản của bạn.
              </p>
              <Form fluid formValue={formValue} onChange={setFormValue}>
                <Form.Group controlId="email">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email"
                    style={{ borderRadius: "2rem" }}
                  />
                </Form.Group>
                <Form.Group>
                  <ButtonToolbar>
                    {loadingSendEmail ? (
                      <Button
                        appearance="default"
                        color="red"
                        className="login-button"
                        style={{
                          backgroundColor: "red",
                          border: "none",
                          color: "white",
                        }}
                      >
                        <Loader />
                      </Button>
                    ) : (
                      <Button
                        appearance="default"
                        color="red"
                        className="login-button"
                        style={{
                          backgroundColor: "red",
                          border: "none",
                          color: "white",
                        }}
                        onClick={handleSendEmail}
                      >
                        Khôi phục mật khẩu
                      </Button>
                    )}
                    <Button
                      appearance="default"
                      className="login-button"
                      onClick={() => navigate("/login")}
                    >
                      Quay lại đăng nhập
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

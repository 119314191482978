import React from "react";
import "./user.css";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import mobile_logo from "../../assets/images/logo/logo-tuvihangngay-đen.png";

const UserHeading = ({ isShowAside, setIsShowAside }) => {
  const handleOpenAside = () => {
    setIsShowAside(true);
  };

  return (
    <div className="userHeading">
      <div></div>
      {/* <div>
        <img
          src={mobile_logo}
          className="mobile-logo"
          alt="tu-vi-thien-an-logo"
        />
      </div> */}
      <div onClick={handleOpenAside}>
        <AiOutlineMenuUnfold className="openButton" />
      </div>
    </div>
  );
};

export default UserHeading;

export const tuoi2024 = [
  {
    id: 1,
    name: "Tý",
    content:
      "<p><strong>Tuổi T&yacute;</strong> l&agrave; những người th&ocirc;ng minh, nhạy b&eacute;n, linh hoạt v&agrave; lanh lợi.</p>\n\n            <p><strong>Trong năm 2024</strong> l&agrave; năm cuối hạn Tam Tai, <strong>tuổi T&yacute;</strong> thể đ&oacute;n nhận nhiều cơ hội mới, như c&oacute; luồng sinh kh&iacute; gi&uacute;p bạn cảm nhận được những niềm vui v&agrave; c&oacute; khả năng x&acirc;y dựng nền tảng cho năm mới bứt ph&aacute;. Tuy nhi&ecirc;n bạn chớ n&ecirc;n chủ quan, tuổi T&yacute; n&ecirc;n c&oacute; th&aacute;i độ thận trọng, lắng nghe v&agrave; học hỏi nhiều hơn...</p>",
  },
  {
    id: 2,
    name: "Sửu",
    content:
      "<p><strong>Tuổi Sửu</strong> l&agrave; những người chăm chỉ, cần c&ugrave;, thật th&agrave; v&agrave; ch&acirc;n th&agrave;nh.</p>\n\n            <p><strong>Trong năm 2024</strong> <strong>tuổi Sửu</strong> v&agrave;o cục diện Ph&aacute; Th&aacute;i Tuế, dự b&aacute;o l&agrave; năm c&oacute; nhiều biến động trong t&agrave;i lộc, t&agrave;i ch&iacute;nh. Bản mệnh cần phải r&agrave; so&aacute;t v&agrave; quản l&yacute; chi ti&ecirc;u một c&aacute;ch cẩn thận, tr&aacute;nh vung tiền qu&aacute; tr&aacute;n. Về phương diện sức khỏe cũng cần ch&uacute; &yacute; n&acirc;ng cao thể chất bằng việc nạp thực phẩm dinh dưỡng, tăng cường sức đề kh&aacute;ng, đặc biệt bạn n&ecirc;n ch&uacute; &yacute; tập luyện thể thao thường xuy&ecirc;n để tăng cường sinh kh&iacute;...</p>",
  },
  {
    id: 3,
    name: "Dần",
    content:
      "<p><strong>Tuổi Dần</strong> l&agrave; những người uy nghi&ecirc;m, dũng m&atilde;nh, kh&iacute; kh&aacute;i v&agrave; c&oacute; bản lĩnh hơn người.</p>\n\n            <p><strong>Trong năm 2024, tuổi Dần</strong> c&oacute; cơ hội ph&aacute;t triển trong sự nghiệp, bạn c&oacute; nhiều cơ may để đạt được mục ti&ecirc;u của ch&iacute;nh m&igrave;nh. Đ&acirc;y hứa hẹn l&agrave; một năm m&agrave; tuổi Dần đ&oacute;n nhận nhiều tin vui trong cuộc sống, tuy nhi&ecirc;n để mục ti&ecirc;u sớm th&agrave;nh hiện thực, bạn cần l&agrave;m việc chăm chỉ hơn, đồng thời h&atilde;y duy tr&igrave; v&agrave; ph&aacute;t triển những mối quan hệ t&iacute;ch cực...</p>",
  },
  {
    id: 4,
    name: "Mão",
    content:
      "<p><strong>Tuổi M&atilde;o</strong> l&agrave; những người tinh tế, nhẹ nh&agrave;ng, kh&ocirc;n ngoan v&agrave; kh&eacute;o l&eacute;o.</p>\n\n            <p><strong>Trong năm 2024 tuổi M&atilde;o</strong> v&agrave;o cục diện Hại Th&aacute;i Tuế, dự b&aacute;o l&agrave; năm c&oacute; nhiều biến động trong tinh thần, nhất l&agrave; những vấn đề li&ecirc;n quan đến sức khỏe, c&ocirc;ng việc c&oacute; những &aacute;p lực khiến bạn cảm thấy qu&aacute; tải. Bản mệnh cần giữ b&igrave;nh tĩnh v&agrave; đ&oacute;n nhận một c&aacute;ch t&iacute;ch cực. Đ&acirc;y sẽ l&agrave; năm gi&uacute;p bạn c&oacute; th&ecirc;m bước ngoặt, chắt lọc được những b&agrave;i học nh&acirc;n sinh đ&aacute;ng gi&aacute;...</p>",
  },
  {
    id: 5,
    name: "Thìn",
    content:
      "<p><strong>Tuổi Th&igrave;n</strong> l&agrave; những người c&oacute; l&yacute; tưởng, sang trọng, th&ocirc;ng th&aacute;i v&agrave; cuốn h&uacute;t.</p>\n\n            <p><strong>Trong năm 2024</strong> l&agrave; năm tuổi của người <strong>tuổi Th&igrave;n</strong>, bạn gặp cục diện H&igrave;nh Th&aacute;i Tuế cũng như năm cuối Tam Tai, v&igrave; thế đối với tuổi Th&igrave;n đ&acirc;y l&agrave; một năm ẩn chức rất nhiều b&agrave;i học qu&yacute; gi&aacute; để bạn c&oacute; thể trau dồi th&ecirc;m kinh nghiệm, kiến thức cũng như r&egrave;n luyện bản lĩnh. Bạn cần ch&uacute; &yacute; đến vấn đề t&agrave;i ch&iacute;nh chi ti&ecirc;u, n&ecirc;n hạn chế đầu tư mạo hiểm; thay v&agrave;o đ&oacute; h&atilde;y b&igrave;nh t&acirc;m suy x&eacute;t, c&acirc;n nhắc cẩn thận, kỹ lưỡng trước khi đưa ra bất kỳ quyết định quan trọng n&agrave;o...</p>",
  },
  {
    id: 6,
    name: "Tỵ",
    content:
      "<p><strong>Tuổi Tỵ</strong> l&agrave; những người nhạy cảm, kh&ocirc;n kh&eacute;o, sắc sảo v&agrave; ưa mạo hiểm.</p>\n\n            <p><strong>Trong năm 2024 tuổi Tỵ</strong> sẽ c&oacute; nhiều cơ hội thể hiện t&agrave;i năng của bản th&acirc;n. Đ&acirc;y được xem l&agrave; một năm kh&aacute; b&igrave;nh ổn, &iacute;t thăng trầm đối với bạn. Điều tuyệt vời l&agrave; bạn sẽ được đ&oacute;n nhận nhiều tin vui phương diện t&igrave;nh duy&ecirc;n v&agrave; gia đạo, đ&acirc;y l&agrave; năm bạn c&oacute; thể gắn kết cũng như mở rộng c&aacute;c mối quan hệ chất lượng, gi&uacute;p bạn c&oacute; th&ecirc;m động lực để tạo n&ecirc;n kỳ t&iacute;ch...</p>",
  },
  {
    id: 7,
    name: "Ngọ",
    content:
      "<p><strong>Tuổi Ngọ</strong> l&agrave; những người thẳng thắn, dũng cảm, độc lập v&agrave; h&agrave;o sảng.</p>\n\n            <p><strong>Trong năm 2024 tuổi Ngọ</strong> sẽ c&oacute; nhiều chuyển biến t&iacute;ch cực hơn, trong đường c&ocirc;ng danh sự nghiệp hay gia đạo t&igrave;nh duy&ecirc;n đều c&oacute; dấu hiệu khởi sắc v&agrave; thuận lợi. Tuổi Ngọ n&ecirc;n tận dụng thời gian n&agrave;y để c&oacute; thể học hỏi t&iacute;ch lũy th&ecirc;m kinh nghiệm, n&acirc;ng cao năng lực để đẩy nhanh hiệu quả. Th&ecirc;m v&agrave;o đ&oacute;, bạn h&atilde;y d&agrave;nh nhiều thời gian hơn cho những người th&acirc;n y&ecirc;u để vun đắp v&agrave; kết nối sợi d&acirc;y t&igrave;nh cảm ng&agrave;y c&agrave;ng bền chặt...</p>",
  },
  {
    id: 8,
    name: "Mùi",
    content:
      "<p><strong>Tuổi M&ugrave;i</strong> l&agrave; những người hiền h&ograve;a, nội t&acirc;m, nh&acirc;n hậu v&agrave; coi trọng t&igrave;nh nghĩa.</p>\n\n            <p><strong>Trong năm 2024 tuổi M&ugrave;i</strong> cần lưu &yacute; vận tr&igrave;nh đ&ocirc;i l&uacute;c c&oacute; biến động, bạn cần phải duy tr&igrave; sự tỉnh t&aacute;o trước mọi vấn đề trong cuộc sống. B&ecirc;n cạnh đ&oacute;, tuổi M&ugrave;i vẫn nhận được cơ hội thăng tiến, t&agrave;i lộc cũng c&oacute; khởi sắc, tuy nhi&ecirc;n bạn chớ n&ecirc;n chủ quan hay chểnh mảng. H&atilde;y d&agrave;nh nhiều thời gian hơn để r&egrave;n luyện v&agrave; chăm s&oacute;c sức khỏe của bản th&acirc;n, đ&acirc;y l&agrave; yếu tố cốt l&otilde;i gi&uacute;p bạn c&oacute; th&ecirc;m năng lượng để đ&oacute;n ch&agrave;o v&agrave; tận hưởng niềm vui mỗi ng&agrave;y...</p>",
  },
  {
    id: 9,
    name: "Thân",
    content:
      "<p><strong>Tuổi Th&acirc;n</strong> l&agrave; những người hoạt b&aacute;t, năng động, cơ tr&iacute; v&agrave; nhiệt t&igrave;nh.</p>\n\n            <p><strong>Trong năm 2024, tuổi Th&acirc;n</strong> bước sang năm cuối Tam Tai, đ&acirc;y cũng l&agrave; địa chi tam hợp với Th&igrave;n, v&igrave; thế đ&acirc;y l&agrave; năm m&agrave; tuổi Th&acirc;n như được tr&uacute;t bỏ g&aacute;nh nặng, vận tr&igrave;nh c&oacute; phần khởi sắc v&agrave; c&oacute; nhiều cơ hội ph&aacute;t triển. Bạn h&atilde;y lu&ocirc;n giữ g&igrave;n sự quyết t&acirc;m, vững v&agrave;ng trong t&acirc;m tr&iacute;, nỗ lực phấn đấu kh&ocirc;ng ngừng, th&igrave; bản mệnh sẽ được thỏa nguyện mong ước, cảm nhận thấy r&otilde; luồng vượng kh&iacute; đ&aacute;o tới...</p>",
  },
  {
    id: 10,
    name: "Dậu",
    content:
      "<p><strong>Tuổi Dậu</strong> l&agrave; những người thẳng thắn, ki&ecirc;n cường, tự tin v&agrave; nghĩa kh&iacute;.</p>\n\n            <p><strong>Trong năm 2024, tuổi Dậu</strong> nhị hợp với địa chi Th&igrave;n, v&igrave; thế hứa hẹn l&agrave; một năm mang lại nhiều trải nghiệm mới tươi s&aacute;ng cho vận tr&igrave;nh đời sống của bạn. H&atilde;y thật chăm chỉ, quyết t&acirc;m để cho c&ocirc;ng danh sự nghiệp th&ecirc;m phần thăng hoa. V&agrave; bạn cũng đừng qu&ecirc;n d&agrave;nh thời gian để ch&iacute;nh m&igrave;nh được nghỉ ngơi, c&ugrave;ng qu&acirc;y quần b&ecirc;n gia đ&igrave;nh để lấy lại năng lượng sau thời gian d&agrave;i ki&ecirc;n tr&igrave;, phấn đấu...</p>",
  },
  {
    id: 11,
    name: "Tuất",
    content:
      "<p><strong>Tuổi Tuất</strong> l&agrave; những người thật th&agrave;, ngay thẳng, dũng cảm v&agrave; ki&ecirc;n định.</p>\n\n            <p><strong>Trong năm 2024</strong> bạn v&agrave;o cục diện Xung Th&aacute;i Tuế, v&igrave; thế đ&acirc;y ch&iacute;nh l&agrave; năm chứng minh bản lĩnh c&ugrave;ng sự quyết t&acirc;m v&agrave; l&ograve;ng dũng cảm của bạn. Vận tr&igrave;nh cuộc sống c&oacute; những biến động khiến <strong>tuổi Tuất</strong> cảm thấy mất đi năng lượng bởi muốn đạt được th&agrave;nh quả th&igrave; bạn phải bỏ ra nhiều c&ocirc;ng sức v&agrave; cống hiến nhiều hơn. H&atilde;y chậm lại v&agrave; qu&aacute;n x&eacute;t cẩn thận những diễn biến xung quanh bạn, cơ hội chỉ đến với những người c&oacute; sự chuẩn bị kỹ lưỡng, h&atilde;y b&igrave;nh tĩnh v&agrave; tr&acirc;n trọng những khoảnh khắc b&igrave;nh y&ecirc;n...</p>",
  },
  {
    id: 12,
    name: "Hợi",
    content:
      "<p><strong>Tuổi Hợi</strong> l&agrave; những người lương thiện, bao dung, vui vẻ v&agrave; tr&aacute;ch nhiệm.</p>\n\n            <p><strong>Trong năm 2024, tuổi Hợi</strong> cần phải chủ động s&aacute;ng tạo v&agrave; tập trung hơn nữa tr&ecirc;n phương diện c&ocirc;ng danh sự nghiệp bởi đ&acirc;y l&agrave; một năm số phận trao cho bạn th&ecirc;m trọng tr&aacute;ch, chỉ c&oacute; những người thực sự nghi&ecirc;m t&uacute;c mới c&oacute; thể gặt h&aacute;i th&agrave;nh quả. B&ugrave; lại, tuổi Hợi lựa chọn an nh&agrave;n, thảnh thơi vẫn c&oacute; tin vui trong t&igrave;nh duy&ecirc;n v&agrave; gia đạo...</p>",
  },
];

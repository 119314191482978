import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Nav, Sidenav } from "rsuite";
import { AiOutlineHome } from "react-icons/ai";
import "./admin.css";
import { useState } from "react";
import WhiteLogo from "../../assets/images/logo/logo-tuvihangngay-trang.png";

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  color: " #fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const Aside = () => {
  const [expanded, setExpanded] = React.useState(true);
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(0);
  const sidebarItem = [
    {
      name: "Trang chủ",
      path: "/admin-home",
    },
    {
      name: "Thanh toán",
      path: "/withdraw",
    },
    {
      name: "Affilliates",
      path: "/admin-affiliates",
    },
    {
      name: "Cung và sao",
      path: "/horoscope",
    },
    {
      name: "Luận giải",
      path: "/horoscope-post",
    },
    {
      name: "Loại",
      path: "/category",
    },
    {
      name: "Bài viết",
      path: "/admin-posts",
    },
    {
      name: "Đơn hàng",
      path: "/admin-orders",
    },
    {
      name: "Coupon",
      path: "/admin-coupon",
    },
    // {
    //   collapse: true,
    //   name: "Test",
    //   child: [
    //     {
    //       name: "Test Child 1",
    //       path: "/test-1",
    //     },
    //     {
    //       name: "Test Child 2",
    //       path: "/test-2",
    //     },
    //     {
    //       name: "Test Child 3",
    //       path: "/test-3",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div className="sidebar">
        <Sidenav
          appearance="inverse"
          expanded={expanded}
          defaultOpenKeys={["3", "4"]}
          style={{ height: "100vh" }}
        >
          <Sidenav.Header>
            <a href="/">
              <img
                src={WhiteLogo}
                alt=""
                style={{ width: "100%", padding: "10px" }}
              />
            </a>
            <div style={headerStyles}>
              <span style={{ marginLeft: 12 }}>ADMIN</span>
            </div>
            {/* <Dropdown title="ADMIN">
              <Dropdown.Item>New File</Dropdown.Item>
            </Dropdown> */}
          </Sidenav.Header>
          <Sidenav.Body>
            <Nav>
              {sidebarItem.map((item, key) => {
                if (item?.collapse) {
                  return (
                    <Nav.Menu key={key} eventKey={key} title={item?.name}>
                      {item?.child?.map((child, idx) => (
                        <Nav.Item
                          key={idx}
                          eventKey={`${key}-${idx}`}
                          onClick={() => navigate(child.path)}
                        >
                          {child.name}
                        </Nav.Item>
                      ))}
                    </Nav.Menu>
                  );
                } else {
                  return (
                    <Nav.Item
                      key={key}
                      eventKey={key}
                      onClick={() => {
                        navigate(item?.path);
                      }}
                    >
                      {item?.name}
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
          {/* <Sidenav.Toggle>
            <Dropdown title="ADMIN">
              <Dropdown.Item>New File</Dropdown.Item>
            </Dropdown>
          </Sidenav.Toggle> */}
        </Sidenav>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  sendDelete,
  sendGet,
  sendPost,
  sendPut,
} from "../../../axios/axiosClient";
import {
  Button,
  Form,
  InputPicker,
  Modal,
  Pagination,
  Panel,
  TagPicker,
  Input,
  useToaster,
  Message,
} from "rsuite";
import Table from "react-bootstrap/Table";
import "./horoscopepost.css";
import { useNavigate } from "react-router-dom";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const HoroscopePost = () => {
  const [listHonoscope, setListHonoscope] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [conditions, setConditions] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [id, setId] = useState(0);
  const [updateItem, setUpdateItem] = useState(null);
  const navigate = useNavigate();

  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const type = [
    {
      label: "Tổng quan",
      value: 0,
    },
    {
      label: "Mệnh",
      value: 1,
    },
    {
      label: "Phụ mẫu",
      value: 2,
    },
    {
      label: "Phúc đức",
      value: 3,
    },
    {
      label: "Điền trạch",
      value: 4,
    },
    {
      label: "Quan lộc",
      value: 5,
    },
    {
      label: "Nô bộc",
      value: 6,
    },
    {
      label: "Thiên di",
      value: 7,
    },
    {
      label: "Tật ách",
      value: 8,
    },
    {
      label: "Tài bạch",
      value: 9,
    },
    {
      label: "Tử nữ",
      value: 10,
    },
    {
      label: "Phu thê",
      value: 11,
    },
    {
      label: "Huynh đệ",
      value: 12,
    },
  ];

  const statusData = [
    {
      label: "Active",
      value: 0,
    },
    {
      label: "Inactive",
      value: 1,
    },
  ];

  const getHonoscope = async () => {
    const input = {
      page_size: 999,
    };
    try {
      const res = await sendGet(`/admin/horoscopes`, input);
      if (res.status === 200) {
        setConditions(
          res?.data?.data?.data?.map((item) => ({
            value: item?.name,
            label: item?.name,
          }))
        );
      }
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      const res = await sendDelete(`/admin/horoscope-posts/${id}`);
      if (res.status === 200) {
        setTextMessage("Xoá thành công.");
        handleCloseDelete();
        getListHoroscopePost();
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const getListHoroscopePost = async () => {
    const input = {
      page: page,
      page_size: limit,
    };
    try {
      const res = await sendGet(`/admin/horoscope-posts`, input);
      if (res.status === 200) {
        const arr = res.data?.data?.data.map((item) => ({
          ...item,
          conditions: JSON.parse(item?.conditions),
          conditions_three_fit: JSON.parse(item?.conditions_three_fit),
          conditions_vcd: JSON.parse(item?.conditions_vcd),
        }));
        // console.log("data edit", arr);

        setListHonoscope(arr);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {}
  };

  const handleType = (value) => {
    return type.find((item) => item?.value === value).label;
  };

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListHoroscopePost();
  }, [limit, page]);

  useEffect(() => {
    getHonoscope();
  }, []);

  return (
    <div>
      <Modal open={openDelete} onClose={handleCloseDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>XOÁ</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc muốn xoá luận giải này?</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleCloseDelete} appearance="subtle">
            Huỷ
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end">
          <Button
            appearance="primary"
            // onClick={handleOpen}
            onClick={() => navigate("/create-horoscope-post")}
          >
            Thêm mới
          </Button>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Tên</th>
              <th>Cung xem</th>
              <th>Tam hợp</th>
              <th>Vô chính diệu</th>
              <th>Nội dung</th>
              <th>Kiểu</th>
              <th>Thanh toán</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {listHonoscope?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td>{item?.title}</td>
                <td>{item?.conditions?.map((x) => x + " ")}</td>
                <td>{item?.conditions_three_fit?.map((x) => x + " ")}</td>
                <td>{item?.conditions_vcd?.map((x) => x + " ")}</td>
                <td style={{ maxWidth: "35vw" }}>{item?.content}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {handleType(item?.type)}
                </td>
                <td>{item?.take_fee ? "Miễn phí" : "Trả phí"}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {item?.status ? "Tạm dừng" : "Hoạt động"}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <Button
                    appearance="primary"
                    color="cyan"
                    onClick={() => {
                      // setUpdateItem(item);
                      // handleOpenUpdate();
                      navigate(`/update-horoscope-post?${item?.id}`);
                    }}
                  >
                    Sửa
                  </Button>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setId(item?.id);
                      handleOpenDelete();
                    }}
                  >
                    Xoá
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default HoroscopePost;

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import route from "../../../layout/route";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import "./home.css";
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputPicker,
  Message,
  Radio,
  RadioGroup,
  useToaster,
} from "rsuite";
import { Form as BsForm } from "react-bootstrap";
import moment from "moment/moment";
import { sendGet, sendPost } from "../../../axios/axiosClient";
import { KetquaContextPro } from "../../../context/KetquaContext";
import { useMedia } from "react-use";
import book_image from "../../../assets/images/demo-home/re-book.webp";
import bat_quai_image from "../../../assets/images/home/inline-12-con-giap_50.webp";
import outline_bat_quai from "../../../assets/images/home/outline-12-con-giap_50.webp";
import { IoIosCall, IoIosShareAlt } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Footer from "../../../layout/footer/Footer";
import logo from "../../../assets/images/logo/logo-tuvihangngay-gold.png";
import Cookies from "js-cookie";
import form_heading_img from "../../../assets/images/text-button/tu-vi-van-han-2024.png";
import search_button_img from "../../../assets/images/text-button/lap_la_so_tu_vi_15.webp";
import zodiac_wheel from "../../../assets/images/home/zodiac-wheel.webp";
import card_cloud_detail from "../../../assets/images/demo-home/re-cloud.png";
import home_bottom_background from "../../../assets/images/home/bottom_home_update_29_12.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { tuoi2024 } from "../../../data/Tuoi2024";
import { Link as ScrollLink } from "react-scroll";
import tu_vi_van_han_2024 from "../../../assets/images/text-button/tu-vi-van-han-2024.png";
import { InfoContextProvider } from "../../../context/InfoContext";

const renderButton = (props, ref) => {
  return (
    <span className="link-animation" {...props} ref={ref}>
      TÀI KHOẢN
    </span>
  );
};

const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export const Home = () => {
  const isWide = useMedia("(min-width: 783px)");
  const ref1 = useRef(null);
  // const isInViewport1 = useIsInViewport(ref1);
  const { ketqua, setKetqua } = useContext(KetquaContextPro);
  const { info, setInfo } = useContext(InfoContextProvider);
  const [formValue, setFormValue] = useState({
    hoten: "",
    birthday: "",
    ngaysinh: "",
    thangsinh: "",
    namsinh: "",
    giosinh: "",
    phutdl: "",
    gender: "1",
    lichxem: "duong",
    ID_THANGXEMNHUAN: 0,
    ID_LUUDAIVAN: 0,
    namxem: Number(moment().format("YYYY")),
    thangxem: Number(moment().format("MM")),
  });
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' + className + ' button-swiper-pagination">' + "</span>"
      );
    },
  };
  // const [formValue, setFormValue] = useState(
  //   JSON.parse(localStorage.getItem("ket-qua-params")) || {
  //     hoten: "",
  //     birthday: "",
  //     ngaysinh: "",
  //     thangsinh: "",
  //     namsinh: "",
  //     giosinh: "",
  //     phutdl: "",
  //     gender: "1",
  //     ID_THANGXEMNHUAN: 0,
  //     ID_LUUDAIVAN: 0,
  //     namxem: Number(moment().format("YYYY")),
  //     thangxem: Number(moment().format("MM")),
  //   }
  // );
  const [positions, setPositions] = useState("");
  const [tuoiData, setTuoiData] = useState([]);
  const [isViewMore, setIsViewMore] = useState(false);
  const gioiTinhData = [
    {
      value: 0,
      label: "Nam",
    },
    {
      value: 1,
      label: "Nữ",
    },
  ];
  const placement = "topCenter";
  const toaster = useToaster();
  const [textMessage, setTextMessage] = useState("");
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] =

  const message = (
    <Message showIcon type="warning" closable>
      {textMessage}
    </Message>
  );

  useEffect(() => {
    if (textMessage !== "") {
      toaster.push(message, { placement, duration: 5000 });
    }
    setTextMessage("");
  }, [textMessage]);

  const handleCreate = async () => {
    if (formValue.hoten.trim() === "") {
      setTextMessage("Chưa điền họ và tên.");
      return;
    } else if (
      formValue?.ngaysinh === "" ||
      formValue?.thangsinh === "" ||
      formValue?.namsinh === "" ||
      formValue?.giosinh === "" ||
      formValue?.phutdl === "" ||
      formValue?.namxem === ""
    ) {
      setTextMessage("Hãy điền đủ thông tin.");
      return;
    }
    try {
      const input = {
        hoten: formValue.hoten,
        ngaysinh: formValue?.ngaysinh,
        thangsinh: formValue?.thangsinh,
        namsinh: formValue?.namsinh,
        giosinh: formValue?.giosinh,
        phutdl: formValue?.phutdl,
        gender: formValue?.gender,
        ID_THANGXEMNHUAN: 1,
        ID_LUUDAIVAN: 1,
        ID_LICH: formValue.lichxem === "duong" ? 1 : 0,
        // ID_THANGXEMNHUAN: formValue.ID_THANGXEMNHUAN ? 1 : 0,
        // ID_LUUDAIVAN: formValue.ID_LUUDAIVAN ? 1 : 0,
        namxem: formValue?.namxem,
        thangxem: formValue?.thangxem,
        guest_code: localStorage.getItem("guest_code"),
      };
      // if (!formValue.ID_THANGXEMNHUAN) {
      //   delete input.ID_THANGXEMNHUAN;
      // }
      // if (!formValue.ID_THANGXEMNHUAN) {
      //   delete input.ID_LUUDAIVAN;
      // }

      const res = await sendGet(`/laso`, input);
      if (res.status === 200) {
        setKetqua(res?.data?.data);
        localStorage.setItem("ket-qua-params", JSON.stringify(input));
        navigate("/ket-qua");
        // setTimeout(() => navigate("/ket-qua"), 1000);
      }
    } catch (error) {
      setTextMessage(error?.response?.data?.error_msg);
    }
  };

  const getAffiliate = async () => {
    try {
      const res = await sendGet(`/user/check-affiliate`);
    } catch (error) {}
  };

  useEffect(() => {
    getAffiliate();
  }, []);

  const getTuoiData = async () => {
    try {
      const res = await sendGet(`/tuoi`);
      if (res.status === 200) {
        // console.log(res, "res");
        setTuoiData(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTuoiData();
  }, []);

  const handleLogout = async () => {
    try {
      const res = await sendGet(`/logout`);
      if (res.status === 200) {
        Cookies.remove("token");
        navigate("/login");
      }
    } catch (error) {
      Cookies.remove("token");
      navigate("/login");
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => handleHoverContent(), 200);
  //   return () => clearTimeout(timer);
  // }, [positions]);

  // const handleHoverContent = async() => {
  //   switch (positions) {
  //     case positions:

  //       break;

  //     default:
  //       break;
  //   }
  // }

  return (
    <div className="">
      <div className="home">
        <div className="home-navbar">
          <Link to={"/"}>
            <div className="d-flex gap-2 align-items-center">
              <img src={logo} alt="" className="logo-home" />
            </div>
          </Link>
          <div className="d-flex gap-5">
            <Link to={"/"} className="link-animation">
              Trang chủ
            </Link>
            <ScrollLink
              to={"zodiac_animal"}
              smooth={true}
              className="link-animation"
            >
              Tử vi 12 con giáp
            </ScrollLink>
            <ScrollLink
              delay={0}
              to={"refForm"}
              smooth={true}
              className="link-animation"
            >
              Xem lá số tử vi
            </ScrollLink>
            <ScrollLink to={"refForm"} smooth={true} className="link-animation">
              Vận hạn 2024
            </ScrollLink>
          </div>

          {/* <CustomDropdown title="Click" trigger="click" /> */}

          {Cookies.get("token") ? (
            <Dropdown renderToggle={renderButton}>
              {info?.role === 1 ? (
                <Dropdown.Item>
                  <Link to={"/admin-home"} style={{ textDecoration: "none" }}>
                    Trang admin
                  </Link>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item>
                  <Link to={"/user"} style={{ textDecoration: "none" }}>
                    Thông tin người dùng
                  </Link>
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={handleLogout}>Đăng xuất</Dropdown.Item>
            </Dropdown>
          ) : (
            <Link to={"/login"} className="link-animation">
              ĐĂNG KÝ/ĐĂNG NHẬP
            </Link>
          )}
        </div>
        <div className="userHomeBody">
          {isWide ? (
            <></>
          ) : (
            <img
              src={tu_vi_van_han_2024}
              alt=""
              className="mobile-heading-text-button"
            />
          )}
          <div className="heading-home">
            <div className="heading-text"></div>
          </div>
          <div className="placeholding-image">
            <img src={book_image} alt="" className="book-image" />
            <img src={bat_quai_image} alt="" className="batquai-image" />
            <img
              src={outline_bat_quai}
              alt=""
              className="batquai-outline-image"
            />
            <div className="animation-button tab-1">
              <span>Vận Hạn 2024</span>
            </div>
            <div className="animation-button tab-2">
              <span>Tài Lộc, Công Danh</span>
            </div>
            <div className="animation-button tab-3">
              <span>Tình Duyên, Gia Đạo</span>
            </div>
            <div className="animation-button tab-4">
              <span>Thấu Rõ Chính Mình</span>
            </div>
            <div className="animation-button tab-5">
              <span>Nắm Bắt Vận Mệnh</span>
            </div>
          </div>
          <div className="home-form">
            {isWide ? (
              <img
                loading="lazy"
                src={form_heading_img}
                alt=""
                className="input-form-heading"
              />
            ) : (
              <></>
            )}
            <div className="user-input-form" ref={ref1} id="refForm">
              <div style={{ width: "100%" }}>
                {isWide ? (
                  <>
                    <div className="d-flex flex-wrap mb-2">
                      <div className="col-12 mb-2">
                        <Input
                          size={"lg"}
                          placeholder="Họ và tên"
                          value={formValue?.hoten}
                          onChange={(e) =>
                            setFormValue({ ...formValue, hoten: e })
                          }
                          maxLength={40}
                          className="home-input"
                        />
                      </div>
                    </div>
                    <div className="ms-4 pb-2 inline-radio radio-button-group">
                      <BsForm.Check
                        type={"radio"}
                        label={`Nam`}
                        id="Nam"
                        className={isWide ? "fs-6" : ""}
                        onClick={() =>
                          setFormValue({ ...formValue, gender: "1" })
                        }
                        checked={formValue.gender === "1"}
                      />
                      <BsForm.Check
                        type={"radio"}
                        label={`Nữ`}
                        id="Nu"
                        className={isWide ? "fs-6" : ""}
                        onClick={() =>
                          setFormValue({ ...formValue, gender: "0" })
                        }
                        checked={formValue.gender === "0"}
                      />
                    </div>

                    <div className="d-flex">
                      <div className="col-4 pe-2">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          cleanable={false}
                          data={Array.from({ length: 31 }, (_, idx) => ({
                            label: idx + 1,
                            value: idx + 1,
                          }))}
                          placeholder="Ngày sinh"
                          value={formValue.ngaysinh}
                          onChange={(e) =>
                            setFormValue({ ...formValue, ngaysinh: e })
                          }
                        />
                      </div>
                      <div className="col-4 pe-2">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          cleanable={false}
                          data={Array.from({ length: 12 }, (_, idx) => ({
                            label: idx + 1,
                            value: idx + 1,
                          }))}
                          placeholder="Tháng sinh"
                          value={formValue.thangsinh}
                          onChange={(e) =>
                            setFormValue({ ...formValue, thangsinh: e })
                          }
                        />
                      </div>
                      <div className="col-4">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          cleanable={false}
                          data={Array.from({ length: 150 }, (_, idx) => ({
                            label: 2024 - idx,
                            value: 2024 - idx,
                          }))}
                          value={formValue.namsinh}
                          placeholder="Năm sinh"
                          onChange={(e) =>
                            setFormValue({ ...formValue, namsinh: e })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 pe-lg-2 mb-2">
                      <Input
                        size="xs"
                        className="home-input"
                        placeholder="Họ và tên"
                        value={formValue?.hoten}
                        onChange={(e) =>
                          setFormValue({ ...formValue, hoten: e })
                        }
                      />
                    </div>
                    <div className="ms-4 radio-button-group">
                      <BsForm.Check
                        type={"radio"}
                        label={`Nam`}
                        id="Nam"
                        className={isWide ? "fs-6" : ""}
                        onClick={() =>
                          setFormValue({ ...formValue, gender: "1" })
                        }
                        checked={formValue.gender === "1"}
                      />
                      <BsForm.Check
                        type={"radio"}
                        label={`Nữ`}
                        id="Nu"
                        className={isWide ? "fs-6" : ""}
                        onClick={() =>
                          setFormValue({ ...formValue, gender: "0" })
                        }
                        checked={formValue.gender === "0"}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="col-4 pe-2">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          data={Array.from({ length: 31 }, (_, idx) => ({
                            label: idx + 1,
                            value: idx + 1,
                          }))}
                          placeholder="Ngày sinh"
                          value={formValue.ngaysinh}
                          onChange={(e) =>
                            setFormValue({ ...formValue, ngaysinh: e })
                          }
                        />
                      </div>
                      <div className="col-4 pe-2">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          data={Array.from({ length: 12 }, (_, idx) => ({
                            label: idx + 1,
                            value: idx + 1,
                          }))}
                          placeholder="Tháng sinh"
                          value={formValue.thangsinh}
                          onChange={(e) =>
                            setFormValue({ ...formValue, thangsinh: e })
                          }
                        />
                      </div>
                      <div className="col-4">
                        <InputPicker
                          size={isWide ? "lg" : "xs"}
                          className="home-input"
                          data={Array.from({ length: 130 }, (_, idx) => ({
                            label: 2024 - idx,
                            value: 2024 - idx,
                          }))}
                          value={formValue.namsinh}
                          placeholder="Năm sinh"
                          onChange={(e) =>
                            setFormValue({ ...formValue, namsinh: e })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="ms-4 py-2 radio-button-group">
                  <BsForm.Check
                    type={"radio"}
                    label={`Dương lịch`}
                    id="Duong"
                    className={isWide ? "fs-6" : ""}
                    onClick={() =>
                      setFormValue({ ...formValue, lichxem: "duong" })
                    }
                    checked={formValue.lichxem === "duong"}
                  />
                  <BsForm.Check
                    type={"radio"}
                    label={`Âm lịch`}
                    id="Am"
                    className={isWide ? "fs-6" : ""}
                    onClick={() =>
                      setFormValue({ ...formValue, lichxem: "am" })
                    }
                    checked={formValue.lichxem === "am"}
                  />
                </div>

                <div className="d-flex">
                  <div className="col-4 pe-2">
                    <InputPicker
                      size={isWide ? "lg" : "xs"}
                      className="home-input"
                      cleanable={false}
                      data={Array.from({ length: 24 }, (_, idx) => ({
                        label: idx.toString(),
                        value: idx,
                      }))}
                      placeholder="Giờ sinh"
                      value={formValue.giosinh}
                      onChange={(e) =>
                        setFormValue({ ...formValue, giosinh: e })
                      }
                    />
                  </div>
                  <div className="col-4 pe-2">
                    <InputPicker
                      size={isWide ? "lg" : "xs"}
                      className="home-input"
                      cleanable={false}
                      data={Array.from({ length: 60 }, (_, idx) => ({
                        label: idx.toString(),
                        value: idx,
                      }))}
                      placeholder="Phút sinh"
                      value={formValue.phutdl}
                      onChange={(e) =>
                        setFormValue({ ...formValue, phutdl: e })
                      }
                    />
                  </div>
                  <div className="col-4">
                    <InputPicker
                      size={isWide ? "lg" : "xs"}
                      className="home-input"
                      data={Array.from({ length: 130 }, (_, idx) => ({
                        label: 2024 - idx,
                        value: 2024 - idx,
                      }))}
                      value={formValue.namxem}
                      placeholder="Năm xem"
                      onChange={(e) =>
                        setFormValue({ ...formValue, namxem: e })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="" style={{ position: "relative", zIndex: "1" }}>
              <img
                // ref={ref1}
                loading="lazy"
                src={search_button_img}
                alt="search-button"
                className="search-button"
                onClick={handleCreate}
              />
            </div>
          </div>
          <div className="home-bottom" id="zodiac_animal">
            <img
              loading="lazy"
              src={home_bottom_background}
              alt=""
              className="home-bottom-background"
            />
            <div className="detail-card">
              <img
                src={zodiac_wheel}
                alt="zodiac-wheel"
                loading="lazy"
                className="zodiac-spin-home"
                onMouseMove={(e) => {
                  setPositions({
                    clientX: e.clientX,
                    clientY: e.clientY,
                  });
                }}
              />
              <img
                src={card_cloud_detail}
                alt="cloud"
                loading="lazy"
                className="detail-card-cloud"
              />
              <div className="mb-3 text-bottom-detail-card">
                Vận hạn 12 con giáp 2024
              </div>
              <div className="_swiper">
                <Swiper
                  pagination={pagination}
                  modules={[Pagination]}
                  className="mySwiper"
                  loop={true}
                >
                  {tuoi2024.map((item, idx) => (
                    <SwiperSlide key={idx} className="swiper__body">
                      {/* <img
                        src="https://hips.hearstapps.com/hmg-prod/images/rabbit-breeds-american-white-1553635287.jpg?crop=0.976xw:0.651xh;0.0242xw,0.291xh&resize=980:*"
                        alt=""
                        className="swiper__image mb-3"
                      /> */}
                      <div
                        className="swiper__content"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      ></div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <ScrollLink delay={0} to={"refForm"} smooth={true}>
                <button className="button-view-more">Xem thêm chi tiết</button>
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

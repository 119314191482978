import React, { useEffect, useState } from "react";
import { sendGet } from "../../../axios/axiosClient";
import { Button, Input, InputGroup, Pagination, Panel } from "rsuite";
import { Table } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";

const Horoscrope = () => {
  const [listHoroscope, setListHoroscope] = useState([]);
  const [listType, setListType] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const getListHoroscope = async () => {
    try {
      const input = {
        page: page,
        page_size: limit,
      };
      const res = await sendGet(`/admin/horoscopes`, input);
      if (res.status === 200) {
        setListHoroscope(res.data?.data?.data);
        setTotalItems(res.data?.data?.total);
      }
    } catch (error) {}
  };

  const getListHonoscopeType = async () => {
    try {
      const input = {
        page_size: 999,
      };
      const res = await sendGet(`/admin/horoscope-types`, input);
      if (res.status === 200) {
        setListType(res.data.data.data);
      }
    } catch (error) {}
  };

  const handleType = (type) => {
    return listType?.find((x) => x.id === type)?.name;
  };

  useEffect(() => {
    getListHonoscopeType();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    getListHoroscope();
  }, [limit, page]);

  return (
    <div>
      <Panel shaded>
        <div className="mb-2 d-flex justify-content-end">
          <div>
            <InputGroup>
              <Input placeholder={"Search"} />
              <InputGroup.Addon>
                <AiOutlineSearch />
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Tên</th>
              <th>Loại</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {listHoroscope?.map((item, idx) => (
              <tr key={item?.id}>
                <td>{++idx + limit * (page - 1)}</td>
                <td>{item?.name}</td>
                <td>{handleType(item?.type_id)}</td>
                <td>{item?.description}</td>
                <td>{item?.status ? "Tạm ngừng" : "Hoạt động"}</td>
                {/* <td>
                  <Button
                    className="ms-2"
                    appearance="primary"
                    color="red"
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        {totalItems > 0 ? (
          <Pagination
            layout={["limit", "-", "pager"]}
            size={"sm"}
            ellipsis
            next
            prev
            boundaryLinks
            total={totalItems}
            limit={limit}
            limitOptions={[10, 30, 50]}
            maxButtons={3}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={setLimit}
          />
        ) : (
          <></>
        )}
      </Panel>
    </div>
  );
};

export default Horoscrope;

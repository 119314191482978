import React from "react";
import "./userAffiliate.css";
import { Link } from "react-router-dom";

const UserAffiliate = () => {
  return (
    <div className="user-affiliate">
      <p className="fs-3 fw-bold text-center ">
        MÃ GIỚI THIỆU (AFFILIATE) CỦA BẠN
      </p>
      <div className="affiliate-body">
        <div>
          <p className="fw-bold fs-6">1. Link giới thiệu của bạn:</p>
          <p>
            Bạn phải là người đã xem luận giải đầy đủ (mua ít nhất 1 lần tra cứ
            VIP hoặc PDF báo cáo) để được cấp mã giới thiệu tracuuthansohoc và
            được nhận chính sách cùng lợi ích về tài chính cho bạn.
          </p>
        </div>
        <div className="mt-3">
          <p className="fw-bold fs-6">
            2. Xem chính sách nhận hoa hồng ở link sau:{" "}
            <Link to={"/affiliate"}>
              {window.location.origin + "/affiliate"}
            </Link>
          </p>
          <p>Liên hệ hotline 0962984269 để hợp tác.</p>
        </div>
      </div>
    </div>
  );
};

export default UserAffiliate;

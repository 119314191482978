import React from "react";
import "./preview.css";
import { Link, useNavigate } from "react-router-dom";
import Laso from "../../../la-so/Laso";
import kim from "../../../../assets/images/menh/kim.webp";
import moc from "../../../../assets/images/menh/moc.webp";
import thuy from "../../../../assets/images/menh/thuy.webp";
import hoa from "../../../../assets/images/menh/hoa.webp";
import tho from "../../../../assets/images/menh/tho.webp";
import { useMedia } from "react-use";

const Preview = ({ ketqua }) => {
  const navigate = useNavigate();
  const isWide = useMedia("(min-width: 600px)");

  const handleBall = () => {
    switch (ketqua?.menh.split(" ")[ketqua?.menh?.split(" ")?.length - 1]) {
      case "Kim":
        return kim;
      case "Mộc":
        return moc;
      case "Thủy":
        return thuy;
      case "Hỏa":
        return hoa;
      case "Thổ":
        return tho;
    }
  };

  return (
    <div>
      <div className="user-information">
        <div className="user-information-heading">
          {/* <span>Lorem ipsum dolor sit.</span> */}
        </div>
        <div className="user-information-background"></div>
        <div className="preview-user-information">
          <div>
            <p className="detail-user-name">{ketqua?.info?.hoten}</p>
            <p className="detail-user-name">
              {Number(ketqua?.info?.ngaysinh) < 10
                ? `0${ketqua?.info?.ngaysinh}`
                : ketqua?.info?.ngaysinh}
              /
              {Number(ketqua?.info?.thangsinh) < 10
                ? `0${ketqua?.info?.thangsinh}`
                : ketqua?.info?.thangsinh}
              /{ketqua?.info?.namsinh} - Giờ{" "}
              {ketqua?.giosinh[ketqua?.info?.giosinh]}
            </p>
            <p className="detail-user-name">Mệnh: {ketqua?.menh}</p>
          </div>
        </div>
      </div>
      <div className="laso">
        <div className="laso-logo"></div>
        <Laso ketqua={ketqua} />
        {isWide ? (
          <></>
        ) : (
          <div
            className="text-white"
            style={{ position: "relative", marginTop: "2rem" }}
          >
            <div
              className="left-ball"
              style={{ backgroundImage: `url(${handleBall()})` }}
            ></div>
            <div
              className="right-ball"
              style={{ backgroundImage: `url(${handleBall()})` }}
            ></div>
            <div>
              <h4 className="text-center" style={{ padding: "0 15vw" }}>
                {ketqua?.luangiai?.find((item) => item?.type === 0)?.title}
              </h4>
              <div
                className="mt-3 px-1"
                dangerouslySetInnerHTML={{
                  __html:
                    ketqua?.luangiai?.find((item) => item?.type === 0)
                      ?.content_take_fee && ketqua?.is_unlocked === 1
                      ? ketqua?.luangiai?.find((item) => item?.type === 0)
                          ?.content_take_fee
                      : ketqua?.luangiai?.find((item) => item?.type === 0)
                          ?.content,
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="luanGiai">
        <div className="luangiai-title"></div>
        <div className="luangiai-content-body">{handleLuangiai(0)}</div>
      </div> */}
      {isWide ? (
        <div className="luan-giai-tong-quan">
          <div
            className="left-ball"
            style={{ backgroundImage: `url(${handleBall()})` }}
          ></div>
          <div
            className="right-ball"
            style={{ backgroundImage: `url(${handleBall()})` }}
          ></div>
          <div>
            <h4 className="text-center" style={{ padding: "0 6vw" }}>
              {ketqua?.luangiai?.find((item) => item?.type === 0)?.title}
            </h4>
            <div
              className="mt-3 px-1"
              dangerouslySetInnerHTML={{
                __html:
                  ketqua?.luangiai?.find((item) => item?.type === 0)
                    ?.content_take_fee && ketqua?.is_unlocked === 1
                    ? ketqua?.luangiai?.find((item) => item?.type === 0)
                        ?.content_take_fee
                    : ketqua?.luangiai?.find((item) => item?.type === 0)
                        ?.content,
              }}
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <div
          className="button-view-detail"
          onClick={() => navigate("/detail")}
        ></div>
      </div>
    </div>
  );
};

export default Preview;
